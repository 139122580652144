import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import RfpGateway from '../../../../../../gateways/RfpGateway/RfpGateway';
import Button from '../../../../../../shared/Button';
import Modal from '../../../../../../shared/Modal';
import { QuantityType, ChartTimezone } from '../../../../../../utils/functions/enums';
import { Resolver } from '../../../../../../utils/functions/Ioc';
import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';

import styles from '../../Header.module.scss';

import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';

import { useFillContextsFromPreferences } from '../../../../../../utils/hooks/preferences/useFillContextsFromPreferences';

import useChangePassword from '../../../../../../utils/mutations/useChangePassword';

import PlatformSettings from './PlatformSettings';
import SecuritySettings from './SecuritySettings';
import TradingSettings from './TradingSettings';

interface settingsModalProps {
	openSettings: boolean;
	setOpenSettings: (openSettings: boolean) => void;
	setResetIdleTimer: Dispatch<SetStateAction<boolean>>;
}

interface passwordSettings {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

interface settings {
	quantityType: 'Amount' | 'Lots';
	tradeFromChartEnabled: boolean;
	oneClickTrading: boolean;
	notificationSoundMuted: boolean;
	notificationSoundVolume: number;
	language: string;
	theme: string;
	timezone: string;
	inactivityLogout: number;
}

interface tabs {
	platformSettings: string;
	tradingSettings: string;
	passwordSettings: string;
}

// convert the UserPreferences object from the _fuzzy_ format stored in the BE
// to something easy to use and back
const parseSettings = (preferences: any = {}): settings => ({
	quantityType: preferences.trading.settings.cfd._quantityType.replace('mm', 'm'),
	tradeFromChartEnabled: preferences.trading.settings.cfd._tradeFromChartEnabled,
	oneClickTrading: preferences.trading.settings.cfd._mode === '1-Click',
	notificationSoundMuted: preferences.trading.settings.cfd._notificationSoundMuted,
	notificationSoundVolume: preferences.trading.settings.cfd._notificationSoundVolume,
	language: preferences.platform.lang._code,
	theme: preferences.platform.theme._type,
	// for some reason we've not added a default value for the timezone, it's there now but we
	// should keep this for now; if no value is preset the dashboard uses Local - check fullContextFromPreferences
	timezone: preferences.platform.timezone._name || ChartTimezone.Local,
	inactivityLogout: preferences.platform.inactivity,
});

const encodeSettings = (currentSettings: any, parsedSettings: any) => {
	currentSettings.trading.settings.cfd._quantityType = parsedSettings.quantityType;
	currentSettings.trading.settings.cfd._tradeFromChartEnabled = parsedSettings.tradeFromChartEnabled;
	currentSettings.trading.settings.cfd._notificationSoundMuted = parsedSettings.notificationSoundMuted;
	currentSettings.trading.settings.cfd._notificationSoundVolume = parsedSettings.notificationSoundVolume;
	currentSettings.trading.settings.cfd._mode = parsedSettings.oneClickTrading ? '1-Click' : 'Regular';
	currentSettings.platform.lang._code = parsedSettings.language;
	currentSettings.platform.theme._type = parsedSettings.theme;
	currentSettings.platform.timezone._name = parsedSettings.timezone;
	currentSettings.platform.inactivity = parsedSettings.inactivityLogout;

	return currentSettings;
};

const SettingsModal: React.FC<settingsModalProps> = ({ openSettings, setOpenSettings, setResetIdleTimer }) => {
	const tt = useShortTranslation('wtr:');
	const { t } = useTranslation();

	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const tradingAccount = dashboardContext.tradingAccount;
	const accountType = dashboardContext.accountType;

	const [disabled, setDisabled] = useState(true);

	// override stored language with the one from the login form
	const initialSettings: settings = parseSettings(appContext.userPreferences!.user_prefs);
	initialSettings.language = appContext.languageSettings;

	const [settings, setSettings] = useState<settings>(initialSettings);
	const [activeTab, setActiveTab] = useState<string>('platformSettings');
	const [passwordSettings, setPasswordSettings] = useState<passwordSettings>({
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	});

	const { mutate: changePassword } = useChangePassword();
	const [isPasswordChangeSuccessful, setIsPasswordChangeSuccessful] = useState<boolean>(true);

	const { mutate: savePreferences } = useSaveUserPreferences();
	const fillContextsFromPreferences = useFillContextsFromPreferences();

	const handleUpdatePasswordSettings = (values: Record<string, string>) => {
		setPasswordSettings({ ...passwordSettings, ...values });
	};

	const handleUpdateSettings = (key: string, value: any) => {
		setSettings({ ...settings, [key]: value });
	};

	useEffect(() => {
		for (const item in initialSettings) {
			const key = item as keyof typeof settings;
			if (settings[key] !== initialSettings[key]) {
				setDisabled(false);
				return;
			}
		}
		setDisabled(true);
	}, [settings]);

	const updateSettings = async () => {
		// maybe split the below into 3 separate functions
		if (activeTab === 'platformSettings') {
			appContext.userPreferences!.user_prefs = encodeSettings(appContext.userPreferences!.user_prefs, settings);

			localStorage.setItem('language', settings.language);
			await savePreferences();

			if (initialSettings.language !== settings.language) {
				window.location.reload();
			}

			fillContextsFromPreferences(appContext.userPreferences!);

			setResetIdleTimer((state) => !state);

			// Hack for "Can't perform a React state update on an unmounted component."
			setTimeout(() => {
				setOpenSettings(false);
			}, 1);
		} else if (activeTab === 'tradingSettings') {
			const RFPConnection = Resolver.resolve(RfpGateway);

			const updatedQuantityType = appContext.isJapanAccount ? 'Amount' : settings.quantityType;
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._quantityType = updatedQuantityType;
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._tradeFromChartEnabled =
				settings.tradeFromChartEnabled;
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._mode = settings.oneClickTrading
				? '1-Click'
				: 'Regular';
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundMuted =
				settings.notificationSoundMuted;
			appContext.userPreferences!.user_prefs.trading.settings[accountType]!._notificationSoundVolume =
				settings.notificationSoundVolume;

			savePreferences();

			dashboardContext.quantityType = updatedQuantityType;
			dashboardContext.tradeFromChartEnabled = settings.tradeFromChartEnabled;

			if (tradingAccount.length > 0) {
				let updatedQuantityType: number;
				if (appContext.isJapanAccount || settings.quantityType === 'Amount') {
					updatedQuantityType = QuantityType.Amount;
				} else {
					updatedQuantityType = QuantityType.Lots;
				}
				tradingAccount.forEach((account) => {
					setTimeout(() => {
						// Skip gym accounts
						if (account.accountType === 'GYM') {
							return;
						}

						RFPConnection.updateQuantityType(account.id, updatedQuantityType);
					}, 1000);
				});
			}
			fillContextsFromPreferences(appContext.userPreferences!);
			setOpenSettings(false);
			return;
		} else if (activeTab === 'passwordSettings') {
			changePassword(passwordSettings, {
				onSuccess: (result) => {
					if (result === 'FAIL') {
						setIsPasswordChangeSuccessful(false);
					} else if (result === 'OK') {
						setIsPasswordChangeSuccessful(true);
						setOpenSettings(false);
					}
				},
				onError: (err) => {
					console.debug('Error retrieving changePassword from TFBO gateway.  Exception: ', err);
				},
			});
		}
	};

	const handleCloseModal = () => {
		setOpenSettings(false);
		appContext.openSettings = false;
		setDisabled(true);
	};

	const tabs: tabs = {
		platformSettings: tt('PLATFORM'),
		tradingSettings: tt('WTR_TRADING'),
		passwordSettings: tt('CHANGE_PASS'),
	};

	return (
		<Modal centered show={openSettings}>
			<Modal.Header className={styles.settingsModalHeader}>
				<Modal.Title className={styles.modalTitle}>
					<div className={styles.settings}>{t('en:NAVIGATION_SETTINGS')}</div>
					<div className={styles.closeIcon} onClick={handleCloseModal}>
						<FontAwesomeIcon icon={['fal', 'times']} size="lg" />
					</div>
				</Modal.Title>
				<div className={styles.optionsContainer}>
					{Object.entries(tabs).map(
						([tabName, tabDisplayText]: [string, string]) =>
							tabDisplayText && (
								<label
									key={tabName}
									className={activeTab === tabName ? styles.settingsTabContainer : styles.settingsTabOption}
									onClick={() => {
										setSettings(initialSettings);
										setActiveTab(tabName);
									}}
								>
									{tabDisplayText}
								</label>
							)
					)}
				</div>
			</Modal.Header>
			<Modal.Body style={{ minHeight: '375px' }}>
				<div className={styles.tab}>
					{activeTab === 'platformSettings' && (
						<PlatformSettings
							settings={settings}
							handleUpdateSettings={handleUpdateSettings}
							setOpenSettings={setOpenSettings}
						/>
					)}
					{activeTab === 'tradingSettings' && (
						<TradingSettings
							settings={settings}
							handleUpdateSettings={handleUpdateSettings}
							setSettings={setSettings}
						/>
					)}
					{activeTab === 'passwordSettings' && (
						<SecuritySettings
							setDisabled={setDisabled}
							handleUpdateSettings={handleUpdateSettings}
							isPasswordChangeSuccessful={isPasswordChangeSuccessful}
							handleUpdatePasswordSettings={handleUpdatePasswordSettings}
						/>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer className={styles.modalFooter}>
				<div className={styles.actionButtons}>
					<Button
						label={t('en:CANCEL')}
						variant="outline"
						size="lg"
						className={styles.cancelButton}
						onClick={handleCloseModal}
					/>
					<Button
						label={t('en:UPDATE')}
						variant="primary"
						disabled={disabled}
						size="lg"
						className={styles.saveButton}
						onClick={updateSettings}
					/>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default SettingsModal;
