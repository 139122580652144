import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';

import tradingAccountStore from '../../../../../store/tradingAccountStore';

import usePreferredFeedId from '../../../../../utils/hooks/usePreferredFeedId';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';

import { checkWatchlistValidation } from '../../MarketsGrid/utils';
import {
  createWatchlistMap,
  DEFAULT_FEED_ID,
  instrumentExistsInAnyWatchlist,
  invalidWatchlistName, maxInstrumentsPerWatchlist,
  maxWatchlistsReached
} from '../../../../../utils/functions/WatchlistUtils';

import Button from '../../../../../shared/Button';
import NewWatchlistsList from './NewWatchlistsList';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';
import CreateWatchlistButton from '../../../Dashboard/Watchlist/CreateWatchlistButton';

import { MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';
import {
  TWatchlistInstrument,
  TWatchlists
} from '../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import styles from '../../MarketsGrid/MarketsGrid.module.scss';

interface AddToWatchlistProps {
  marketItem: MarketItem;
}

const AddToWatchlistWrapper: FC<AddToWatchlistProps> = ({
  marketItem
}): JSX.Element => {
  const { t } = useTranslation();
  const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
  const activeTradingAccount = useSelectedTradingAccount();
  const preferredFeedId = usePreferredFeedId(activeTradingAccount);

  const appContext = useContext(AppContext);
  const dashboardContext = useContext(DashboardContext);

  const isArabic = appContext.isArabic;
  const languageSettings = appContext.languageSettings;
  const selectedTradingAccountWatchlists = dashboardContext.watchlist;

  const currentWatchList = tradingAccountStore.use.currentWatchList();
  const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

  const [openWatchlistMenu, setOpenWatchlistMenu] = useState<boolean>(false);
  const [createWatchList, setCreateWatchList] = useState<boolean>(false);
  const [typedValue, setTypedValue] = useState<string>('');
  const [duplicateName, setDuplicateName] = useState<boolean>(false);
  const [resizeText, setResizeText] = useState<boolean>(false);

  useEffect(() => {
    if (['zh-Hans', 'zh-Hant', 'ja', 'de'].includes(languageSettings)) {
      setResizeText(true);
    }
  }, [languageSettings, setResizeText]);

  // Popup position
  const popupPosition = useMemo(() => {
    return isArabic ? 'bottom left' : 'bottom right';
  }, [isArabic]);

  const instrumentExistsInWatchlist = useMemo(() => {
    if (!dashboardContext.mappedWatchlist) {
      return false;
    }

    return instrumentExistsInAnyWatchlist(dashboardContext.mappedWatchlist, marketItem);
  }, [dashboardContext.mappedWatchlist, marketItem]);

  const maxWatchlistLimit = useMemo(() => {
    if (!dashboardContext.mappedWatchlist) {
      return false;
    }

    return maxWatchlistsReached(dashboardContext.mappedWatchlist);
  }, [dashboardContext.mappedWatchlist]);

  const handleOpenWatchlist = () => {
    if (
      dashboardContext.mappedWatchlist &&
      Object.keys(dashboardContext.mappedWatchlist).length === 1
    ) {
      let instruments: TWatchlistInstrument[] = [];
      const isMaxedOut = maxInstrumentsPerWatchlist(dashboardContext.mappedWatchlist, currentWatchList);

      if (!instrumentExistsInWatchlist && !isMaxedOut) {
        instruments = [
          ...dashboardContext.mappedWatchlist[currentWatchList],
          { _code: marketItem.code }
        ];
      } else {
        instruments = dashboardContext.mappedWatchlist[currentWatchList].filter(
          (symbol) => symbol._code !== marketItem.code
        );
      }
      selectedTradingAccountWatchlists[0].instrument = instruments;
      dashboardContext.watchlist = selectedTradingAccountWatchlists;
      dashboardContext.mappedWatchlist = createWatchlistMap(dashboardContext.watchlist);
      saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
    } else {
      setOpenWatchlistMenu(state => !state);
      setCreateWatchList(false);
    }
  };

  const handleCreateWatchlist = useCallback(() => {
    if (!maxWatchlistLimit) {
      setCreateWatchList(state => !state);
      setTypedValue('');
    }
  }, [maxWatchlistLimit, setCreateWatchList, setTypedValue]);

  const handleCreateWatchListClose = useCallback(() => {
    setCreateWatchList(false);
    setTypedValue('');
    setDuplicateName(false);
  }, [setCreateWatchList, setTypedValue, setDuplicateName]);

  const changeValue = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setTypedValue(value);
    duplicateName && setDuplicateName(false);
  }, [setTypedValue, duplicateName, setDuplicateName]);

  const handleSaveWatchlist = () => {
    if (dashboardContext.mappedWatchlist) {
      const addInstrument =
        marketItem && marketItem.code
          ? dashboardContext.marketItems.filter((item: MarketItem) => item.code === marketItem.code)
          : [];

      const alreadyExists = invalidWatchlistName(typedValue.trim(), dashboardContext.mappedWatchlist);

      setDuplicateName(alreadyExists);

      if (typedValue.trim().length > 0 && !alreadyExists && dashboardContext.mappedWatchlist) {
        const newWatchlistEntry: TWatchlists = {
          instrument: [{ _code: addInstrument[0].code }],
          _name: typedValue.trim(),
          _feedId: preferredFeedId !== '' ? preferredFeedId : DEFAULT_FEED_ID,
          _id: typedValue.trim(),
          _sortOrder: 'None',
          _sortValue: 'Instrument'
        };

        const watchlistObj = {
          ...dashboardContext.mappedWatchlist,
          [typedValue.trim()]: [{ _code: addInstrument[0].code }]
        };

        dashboardContext.mappedWatchlist = watchlistObj;
        dashboardContext.watchlist.push(newWatchlistEntry);
        saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
        setCreateWatchList(false);
      }
    }
  };

  return (
    <div key={`watchListPopup${marketItem}`}>
      {openWatchlistMenu && (
        <WtrPopup
          open={openWatchlistMenu}
          className={styles.Popup}
          onClose={() => setOpenWatchlistMenu(false)}
          content={
            !createWatchList ? (
              <div className={styles.watchlistsListContainer}>
                <NewWatchlistsList
                  instrumentExistsInWatchlist={instrumentExistsInWatchlist}
                  marketItemCode={marketItem.code}
                />

                <CreateWatchlistButton
                  maxWatchlistsError={maxWatchlistLimit}
                  handleCreateWatchlist={handleCreateWatchlist}
                />
              </div>
            ) : (
              <>
                <div className={styles.createWLContainer}>
                  <div className={styles.createWatchlistItems}>{t('wtr:WATCHLIST_NAME')}</div>

                  <div className={styles.inputContainer}>
                    <div className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</div>
                    <input
                      type="text"
                      className={duplicateName ? styles.errorInput : styles.costInput}
                      maxLength={20}
                      value={typedValue}
                      onChange={changeValue}
                      autoFocus
                    />
                    <div className={styles.messageRow}>
                      {duplicateName && <div className={styles.errorMessage}>{t('wtr:NAME_IN_USE')}</div>}
                      <span className={styles.inputCount}>{typedValue.trim().length}/20</span>
                    </div>
                  </div>
                  <div className={styles.popupFooter}>
                    <Button
                      variant="secondary"
                      size="sm"
                      label={t('en:CANCEL')}
                      className={(resizeText && styles.resizedCancelBtn) || ''}
                      onClick={handleCreateWatchListClose}
                    />
                    <Button
                      variant="primary"
                      size="sm"
                      label={t('en:CREATE')}
                      className={(resizeText && styles.resizedSaveBtn) || ''}
                      onClick={() => handleSaveWatchlist()}
                      disabled={checkWatchlistValidation(typedValue, dashboardContext.mappedWatchlist)}
                    />
                  </div>
                </div>
              </>
            )
          }
          on={['click']}
          pinned
          position={popupPosition}
          trigger={
            <div>
              <OverlayTrigger
                key={`marketGridWatchList${marketItem.code}`}
                delay={{ show: 750, hide: 0 }}
                placement={isArabic ? 'right' : 'left'}
                overlay={
                  <Tooltip className="my-tooltip" id={`marketGridWatchList${marketItem.code}`}>
                    <div>{t('wtr:ADD_TO_WATCHLIST')}</div>
                  </Tooltip>
                }
              >
                <div>
                  <FontAwesomeIcon
                    id={instrumentExistsInWatchlist ? 'existsIcon' : 'addIcon'}
                    icon={instrumentExistsInWatchlist ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
                    className={cn({
                      [styles.existsInWatchlistIcon]: instrumentExistsInWatchlist,
                      [styles.addToWatchlistIcon]: !instrumentExistsInWatchlist
                    })}
                    onClick={handleOpenWatchlist}
                  />
                </div>
              </OverlayTrigger>
            </div>
          }
        />
      )}
      {
        !openWatchlistMenu && (
          <FontAwesomeIcon
            id={instrumentExistsInWatchlist ? 'existsIcon' : 'addIcon'}
            icon={instrumentExistsInWatchlist ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
            className={cn({
              [styles.existsInWatchlistIcon]: instrumentExistsInWatchlist,
              [styles.addToWatchlistIcon]: !instrumentExistsInWatchlist
            })}
            onClick={handleOpenWatchlist}
          />
        )
      }
    </div>
  );
};

export default AddToWatchlistWrapper;