import React, { useEffect, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import useSignalsAccessRestriction from '../../../../utils/hooks/useSignalsAccessRestriction';

import DashboardContext from '../../../../contexts/DashboardContext';

import { AppComponentType } from '../../../../utils/functions/enums';

import MarketSignalsGate from './MarketSignalsGate/MarketSignalsGate';

import { MarketSignalsList } from './MarketSignalsList';

import styles from './MarketSignals.module.scss';

const MarketSignals = () => {
	const { t } = useTranslation();
	const { presentComponentType } = useContext(DashboardContext);
	const { data: isUserAllowedToAccessSignals, refetch } = useSignalsAccessRestriction({ enabled: false });

	useEffect(() => {
		if (presentComponentType === AppComponentType.Markets) refetch();
	}, [presentComponentType]);


	return (
		<div className={styles.container}>
			<div className={styles.header}>{t('wtr:TOP_SIGNALS')}</div>
			{isUserAllowedToAccessSignals ? (
				<MarketSignalsList />
			) : (
				<div className={styles.gateContainer}>
					<div className={styles.fakeList}>
						<MarketSignalsList />
					</div>
					<div className={styles.gate}>
						<MarketSignalsGate />
					</div>
				</div>
			)}
		</div>
	);
};

export default MarketSignals;
