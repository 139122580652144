import React, { useContext, useState } from 'react';

import AppContext from '../../../contexts/AppContext';

import WtrPopup from '../WtrPopup/WtrPopup';

import CommunityList from './components/CommunityList/CommunityList';
import CommunityIcon from './components/CommunityIcon/CommunityIcon';

const Community = () => {
	const appContext = useContext(AppContext);
	const { isArabic } = appContext;

	const [open, setOpen] = useState<boolean>(false);

	return (
		<>
			<WtrPopup
				on={['click']}
				open={open}
				position={isArabic ? 'bottom right' : 'bottom left'}
				basic
				trigger={
					<div>
						<CommunityIcon open={open} onClick={() => setOpen((open) => !open)} />
					</div>
				}
				content={<CommunityList setOpen={setOpen} />}
			/>
		</>
	);
};

export default Community;
