import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';

import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';

import AppContext from '../../../../../../contexts/AppContext';
import {
	isPasswordValidLength,
	isPasswordSingleLetter,
	isPasswordLowercaseLetter,
	isPasswordSpecialCharacters,
	isPasswordSingleUpperLetter,
	isPasswordSingleDigit,
} from '../../../../../../utils/functions/passwordValidation';
import WtrPopup from '../../../../../components/WtrPopup/WtrPopup';

import styles from './SettingsModule.module.scss';

interface paswordValidations {
	validSatisfied: boolean;
	lowercaseSatisfied: boolean;
	uppercaseSatisfied: boolean;
	digitSatisfied: boolean;
	specialSatisfied: boolean;
}
interface securitySettingsProps {
	handleUpdateSettings: (key: any, value: any) => void;
	handleUpdatePasswordSettings: (values: Record<string, string>) => void;
	setDisabled: (value: boolean) => void;
	isPasswordChangeSuccessful: boolean;
}

const SecuritySettings: React.FC<securitySettingsProps> = ({
	handleUpdateSettings,
	setDisabled,
	isPasswordChangeSuccessful,
	handleUpdatePasswordSettings,
}) => {
	const appContext = useContext(AppContext);

	const tt = useShortTranslation('wtr:');

	const [invalid, setInvalid] = useState(false);
	const [dontMatch, setDontMatch] = useState(false);
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [showPasswordPopup, setShowPasswordPopup] = useState(false);
	const [currentPassword, setCurrentPassword] = useState<string>('');
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [passwordValidations, setPasswordValidations] = useState<paswordValidations>({
		validSatisfied: false,
		lowercaseSatisfied: false,
		uppercaseSatisfied: false,
		digitSatisfied: false,
		specialSatisfied: false,
	});

	const isNewPasswordValid = (value: string) => {
		value !== '' &&
			setPasswordValidations({
				...passwordValidations,
				validSatisfied: isPasswordValidLength(value),
				lowercaseSatisfied: isPasswordLowercaseLetter(value),
				uppercaseSatisfied: isPasswordSingleUpperLetter(value),
				digitSatisfied: isPasswordSingleDigit(value),
				specialSatisfied: isPasswordSpecialCharacters(value),
			});
	};

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
		setShowPasswordPopup(key === 'newPassword');
		isNewPasswordValid(key === 'newPassword' ? event.target.value : '');

		switch (key) {
			case 'currentPassword':
				setCurrentPassword(event.target.value);
				break;
			case 'newPassword':
				setNewPassword(event.target.value);
				break;
			case 'confirmPassword':
				setConfirmPassword(event.target.value);
				break;
		}
	};

	useEffect(() => {
		if (confirmPassword === newPassword && newPassword !== '' && confirmPassword !== '') {
			setDontMatch(false);

			if (
				!passwordValidations.validSatisfied ||
				!passwordValidations.lowercaseSatisfied ||
				!passwordValidations.uppercaseSatisfied ||
				!passwordValidations.digitSatisfied ||
				!passwordValidations.specialSatisfied
			) {
				setErrorMessage(true);
				return;
			}

			if (
				!passwordValidations.validSatisfied &&
				!passwordValidations.lowercaseSatisfied &&
				!passwordValidations.uppercaseSatisfied &&
				!passwordValidations.digitSatisfied &&
				!passwordValidations.specialSatisfied
			) {
				setInvalid(true);
				return;
			}

			if (
				passwordValidations.validSatisfied &&
				passwordValidations.lowercaseSatisfied &&
				passwordValidations.uppercaseSatisfied &&
				passwordValidations.digitSatisfied &&
				passwordValidations.specialSatisfied &&
				confirmPassword === newPassword
			) {
				setDontMatch(false);
				setInvalid(false);
				setErrorMessage(false);
				setDisabled(false);

				handleUpdatePasswordSettings({
					currentPassword: currentPassword,
					newPassword: newPassword,
					confirmPassword: confirmPassword,
				});
				return;
			}
		} else if (confirmPassword !== newPassword) {
			setDontMatch(true);
		}

		handleUpdatePasswordSettings({
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		});
	}, [newPassword, currentPassword, confirmPassword]);

	// useEffect(() => {
	//     mounted.current = true
	//     if (mounted.current) {
	//         if (currentPassword.length > 0 && confirmPassword.length > 0 && validSatisfied && uppercaseSatisfied && lowercaseSatisfied && digitSatisfied && specialSatisfied) {
	//             setDisabled(false)
	//         } else {
	//             setDisabled(true)
	//             setDontMatch(false)
	//         }
	//     }
	//     return () => {
	//         mounted.current = false
	//     }
	// }, [newPassword, currentPassword, confirmPassword])

	return (
		<>
			<div className={styles.platformInactivity}>
				<div className={styles.platformContainer}>
					<div>{tt('CURRENT_PASSWORD')}</div>
					<input
						type="password"
						className={styles.costInput}
						value={currentPassword}
						onChange={(event) => handleChangeInput(event, 'currentPassword')}
						autoFocus
						autoComplete="new-password"
					/>
				</div>
				{invalid ||
					(!isPasswordChangeSuccessful && <div className={styles.errorMessage}>{tt('WTR_INVALID_PASSWORD')}</div>)}
			</div>

			<div className={styles.platformInactivity}>
				<div className={styles.platformContainer}>
					<div>{tt('WTR_NEW_PASSWORD')}</div>
					<WtrPopup
						className={styles.passPopup}
						content={
							<div className={styles.passwordPopup}>
								<div className={styles.headerMessage}>{tt('PASSWORD_CONTAINS')} </div>
								<div className={styles.radioBtns}>
									<div className={styles.check}>
										<Checkbox checked={passwordValidations.validSatisfied} label={tt('PASSWORD_CHARACTERS')}></Checkbox>
									</div>
									<div className={styles.check}>
										<Checkbox
											checked={passwordValidations.uppercaseSatisfied}
											label={tt('AT_LEAST_ONE_UPPER_LETTER')}
										></Checkbox>
									</div>
									<div className={styles.check}>
										<Checkbox
											checked={passwordValidations.lowercaseSatisfied}
											label={tt('AT_LEAST_ONE_LOWER_LETTER')}
										></Checkbox>
									</div>
									<div className={styles.check}>
										<Checkbox checked={passwordValidations.digitSatisfied} label={tt('AT_LEAST_ONE_NUMBER')}></Checkbox>
									</div>
									<div className={styles.check}>
										<Checkbox
											checked={passwordValidations.specialSatisfied}
											label={tt('AT_LEAST_ONE_SPECIAL_SYMBOL')}
										></Checkbox>
									</div>
								</div>
							</div>
						}
						on={['click', 'focus']}
						open={showPasswordPopup}
						pinned
						position={appContext.isArabic ? 'left center' : 'right center'}
						trigger={
							<div>
								<input
									type="password"
									className={styles.costInput}
									value={newPassword}
									onChange={(event) => handleChangeInput(event, 'newPassword')}
								/>
								<FontAwesomeIcon
									className={styles.questionIcon}
									onClick={() => setShowPasswordPopup(!showPasswordPopup)}
									icon={['fas', 'question-circle']}
									size="1x"
								/>
							</div>
						}
					/>
				</div>
				{(!passwordValidations.validSatisfied ||
					!passwordValidations.uppercaseSatisfied ||
					!passwordValidations.lowercaseSatisfied ||
					!passwordValidations.digitSatisfied ||
					!passwordValidations.specialSatisfied ||
					errorMessage) &&
					newPassword.length > 0 && <div className={styles.errorMessage}>{tt('PASSWORD_NOT_SECURE')}</div>}
			</div>
			<div className={styles.platformInactivity}>
				<div className={styles.platformContainer}>
					<div>{tt('REENTER_PASS')}</div>
					<input
						type="password"
						className={styles.costInput}
						value={confirmPassword}
						onChange={(event) => handleChangeInput(event, 'confirmPassword')}
					/>
				</div>
				{dontMatch && <div className={styles.errorMessage}>{tt('PASS_ERR1')}</div>}
			</div>
		</>
	);
};

export default SecuritySettings;
