interface IRoutes {
	account: {
		login: string;
		emailVerification: string;
		forgotPassword: string;
		registered: string;
		twoFactorAuthentication: string;
		sso: string;
		mobile: string;
		agreeToTermsAndConditions: string;
	};
	trader: {
		tradersGym: string;
		charts: string;
		feedback: string;
		markets: string;
		settings: string;
		watchlist: string;
		guide: string;
		openAccount: string;
		uploadDocuments: string;
		funds: string;
		status: string;
		reports: string;
		calendar: string;
		signals: string;
		tutorials: string;
	};
	error: string;
	newWindow: string;
}

export const webTraderPath = '/web-trader';
export const accountPath = '/account';

const Routes: IRoutes = {
	account: {
		login: `${accountPath}/login`,
		emailVerification: `${accountPath}/email-verification`,
		forgotPassword: `${accountPath}/forgotpassword`,
		registered: `${accountPath}/registered`,
		twoFactorAuthentication: `${accountPath}/2fa`,
		sso: `${accountPath}/sso`,
		mobile: `${accountPath}/mobile`,
		agreeToTermsAndConditions: `${accountPath}/agreement`,
	},
	trader: {
		tradersGym: `${webTraderPath}/gym`,
		charts: `${webTraderPath}/charts`,
		feedback: `${webTraderPath}/feedback`,
		markets: `${webTraderPath}/markets`,
		calendar: `${webTraderPath}/calendar`,
		settings: `${webTraderPath}/settings`,
		watchlist: `${webTraderPath}/watchlist`,
		guide: `${webTraderPath}/guide`,
		openAccount: `${webTraderPath}/open-account`,
		uploadDocuments: `${webTraderPath}/funds`,
		funds: `${webTraderPath}/funds`,
		status: `${webTraderPath}/funds/status`,
		reports: `${webTraderPath}/reports`,
		signals: `${webTraderPath}/signals`,
		tutorials: `${webTraderPath}/tutorials`,
	},
	error: '/error',
	newWindow: '/new-window',
};

export default Routes;
