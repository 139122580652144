import { library } from '@fortawesome/fontawesome-svg-core';

import { faTwitter, faTelegram, faLine } from '@fortawesome/free-brands-svg-icons';

import {
	faTimes as fasTimes,
	faPen as fasPen,
	faTimesCircle as fasTimesCircle,
	faSearch as fasSearch,
	faChevronLeft as fasChevronLeft,
	faPlusCircle as fasPlusCircle,
	faInfoCircle as fasInfoCircle,
	faExternalLinkAlt as fasExternalLinkAlt,
	faCog as fasCog,
	faUser as fasUser,
	faList as fasList,
	faEye,
	faFileAlt,
	faCheckCircle,
	faTasks,
	faHistory,
	faChartPie,
	faCaretUp,
	faCaretDown,
	faSpinner,
	faExclamationCircle,
	faCaretLeft as fasCaretLeft,
	faCaretRight as fasCaretRight,
	faArrowLeft as fasArrowLeft,
	faMoon,
	faSun,
	faBrightness,
	faCommentAltDots as fasCommentAltDots,
	faDumbbell as fasDumbbell,
	faPlayCircle as fasPlayCircle,
	faPauseCircle as fasPauseCircle,
	faStepBackward,
	faStepForward,
	faChevronUp,
	faChevronDown,
	faSignalSlash,
	faBookReader,
	faGripVertical,
	faGripDotsVertical,
	faBolt,
	faCommentDots as fasCommentDots,
	faTruck,
	faTrashAlt,
	faAngleUp,
	faAngleDown,
	faAngleRight,
	faExclamationTriangle,
	faExchange,
	faInfoCircle,
	faEllipsisV,
	faPlus as fasPlus,
	faBookOpenCover,
	faBoltSlash,
	faVolume,
	faVolumeSlash,
	faBell,
	faInfo,
	faLayerPlus,
	faQuestionCircle,
	faTrash,
	faMessageDots as fasMessageDots,
	faWifiSlash,
	faSync,
	faGlobe as fasGlobe,
	faChartCandlestick as fasChartCandlestick,
	faSignal as fasSignal,
	faCalendar as fasCalendar,
	faMoneyBill1 as fasMoneyBill1,
	faMoneyBillTrendUp,
	faPeopleGroup as fasPeopleGroup,
	faCircleQuestion as fasCircleQuestion,
	faClapperboardPlay as fasClapperboardPlay,
	faEyeSlash as fasEyeSlash,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faTimes as falTimes,
	faPlus as falPlus,
	faSquare,
	faCommentDots,
	faLock,
	faEye as falEye,
	faEyeSlash as falEyeSlash,
	faArrowRightArrowLeft,
	faChartCandlestick,
	faGlobe,
	faCircleXmark,
	faXmark,
	faMoneyBill1,
	faCalendar,
	faInfoCircle as falInfoCircle,
	faNewspaper as falNewspaper,
	faChartTreeMap,
	faMoneyBillWave,
	faUser as falUser,
	faCheckSquare as fasCheckSquare,
	faSignal,
	faBookOpenCover as falBookOpenCover,
	faClone,
	faDumbbell as falDumbbell,
	faBell as falBell,
	faPeopleGroup as falPeopleGroup,
	faCircleQuestion as falCircleQuestion,
	faClapperboardPlay as falClapperboardPlay,
	faSparkles,
	faArrowDownToLine,
} from '@fortawesome/pro-light-svg-icons';

import {
	faPlusCircle,
	faPauseCircle,
	faPlayCircle,
	faPen,
	faLock as farLock,
	faTimesCircle as farTimesCircle,
	faBell as farBell,
	faCreditCard as farCreditCard,
	faCommentAltDots,
	faDumbbell,
	faGamepad,
	faRedo,
	faCheck,
	faFilter,
	faPlus,
	faArrowLeft,
	faMinus,
	faPenLine,
	faGrid,
	faFunction,
	faGear,
	faNewspaper,
	faInfoCircle as farInfoCircle,
	faArrowRightArrowLeft as farArrowRightArrowLeft,
	faMessageDots,
	faArrowRightFromBracket,
	faPhone,
	faClipboardList,
	faEnvelope,
	faDumbbell as farDumbbell,
	faPeopleGroup,
	faEyeSlash as farEyeSlash,
	faEye as farEye,
	faCircleQuestion,
	faClapperboardPlay as farClapperboardPlay,
	faExclamationTriangle as farExlamationTriangle,
	faBookOpenCover as farBookOpenCover,
	faPencil,
	faCalendarAlt,
	faGlobe as farGlobe,
	faXmark as farXmark,
	faChevronDown as farChevronDown,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
	faCalendar,
	fasCalendar,
	fasTimes,
	fasPen,
	fasTimesCircle,
	fasSearch,
	faPencil,
	faArrowDownToLine,
	fasChevronLeft,
	fasPlusCircle,
	fasInfoCircle,
	fasExternalLinkAlt,
	fasCog,
	fasUser,
	faGripDotsVertical,
	falTimes,
	fasList,
	falNewspaper,
	faGlobe,
	farGlobe,
	fasGlobe,
	faPlusCircle,
	faEye,
	farEye,
	falEye,
	falEyeSlash,
	fasEyeSlash,
	farEyeSlash,
	faFileAlt,
	faCircleXmark,
	faXmark,
	faCheckCircle,
	faTasks,
	faHistory,
	faChartPie,
	falPlus,
	faCaretUp,
	faSpinner,
	faCaretDown,
	faClipboardList,
	faExclamationCircle,
	faPauseCircle,
	faPen,
	farTimesCircle,
	fasCaretLeft,
	fasCaretRight,
	faSquare,
	fasCheckSquare,
	faBell,
	farBell,
	falBell,
	faPlayCircle,
	fasArrowLeft,
	faSun,
	faBrightness,
	faMoon,
	farCreditCard,
	faTwitter,
	faTelegram,
	faLine,
	faCommentAltDots,
	fasCommentAltDots,
	faDumbbell,
	faCommentDots,
	fasCommentDots,
	fasDumbbell,
	falDumbbell,
	farDumbbell,
	fasPlayCircle,
	fasPauseCircle,
	faStepBackward,
	faStepForward,
	faGamepad,
	faRedo,
	faMoneyBillWave,
	faCheck,
	faSignal,
	fasSignal,
	faFilter,
	faChevronUp,
	faChevronDown,
	farChevronDown,
	farXmark,
	faSignalSlash,
	farLock,
	faLock,
	faBookReader,
	faGripVertical,
	faBolt,
	faBookOpenCover,
	falBookOpenCover,
	faNewspaper,
	faArrowRightArrowLeft,
	faPlus,
	faMinus,
	faTruck,
	faTrashAlt,
	faAngleUp,
	faAngleDown,
	faAngleRight,
	faExclamationTriangle,
	faExchange,
	faInfoCircle,
	faChartCandlestick,
	fasChartCandlestick,
	fasPlus,
	faMoneyBill1,
	fasMoneyBill1,
	faPenLine,
	faEllipsisV,
	faGrid,
	faFunction,
	faGear,
	falInfoCircle,
	farArrowRightArrowLeft,
	farInfoCircle,
	faBoltSlash,
	faChartTreeMap,
	faMessageDots,
	fasMessageDots,
	faArrowRightFromBracket,
	faPhone,
	falUser,
	faEnvelope,
	faVolume,
	faVolumeSlash,
	faLayerPlus,
	faInfo,
	faQuestionCircle,
	faTrash,
	faWifiSlash,
	faSync,
	faClone,
	faPeopleGroup,
	falPeopleGroup,
	fasPeopleGroup,
	fasCircleQuestion,
	falCircleQuestion,
	faCircleQuestion,
	fasClapperboardPlay,
	falClapperboardPlay,
	farClapperboardPlay,
	faMoneyBillTrendUp,
	farExlamationTriangle,
	farBookOpenCover,
	faSparkles,
	farBookOpenCover,
	faArrowLeft,
	faCalendarAlt
);
