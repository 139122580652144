import React, { useState, useContext, RefObject, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { forceCloseModal } from '../../../../../utils/hooks/useForceCloseModal';

import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';
import usePromiseFactory from '../../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../../utils/hooks/useForceRerender';
import useObservable from '../../../../../utils/hooks/useObservable';

import Button from '../../../../../shared/Button';

import WtrTooltip from '../../../../../shared/WtrTooltip/WtrTooltip';

import WtrToggle from '../../../../../shared/WtrToggle/WtrToggle';

import useSaveUserPreferences from '../../../../../utils/mutations/useSaveUserPreferences';

import InfoModal from '../../../../../components/InfoModal/InfoModal';

import styles from './OneClickTradingToggle.module.scss';

const OneClickTradingToggle = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const parentRef: RefObject<HTMLDivElement> = useRef(null);
	const isArabic = !!appContext.isArabic || !!(localStorage.getItem('language') === 'ar-SA');
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const { mutate: savePreferences } = useSaveUserPreferences();
	const { t } = useTranslation();

	const [openActivateModal, setOpenActivateModal] = useState<boolean>(false);

	const oneClickTrading = dashboardContext.oneClickTrading;

	useObservable(dashboardContext.getPropertyChangeStream('oneClickTrading'), () => {
		promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	const handleClick = () => {
		if (!oneClickTrading) {
			setOpenActivateModal(true);
		} else {
			updateOneClickTrading();
		}
	};

	const handleCancel = () => {
		setOpenActivateModal(false);
		forceCloseModal('fade modal');
	};

	const handleActivate = () => {
		updateOneClickTrading();
		setOpenActivateModal(false);
		forceCloseModal('fade modal');
	};

	const updateOneClickTrading = () => {
		const updated = !oneClickTrading;

		dashboardContext.oneClickTrading = updated;
		appContext.userPreferences!.user_prefs.trading.settings.cfd!._mode = updated ? '1-Click' : 'Regular';
		savePreferences();
		dashboardContext.oneClickTrading = updated;
	};

	return (
		<>
			<WtrTooltip
				content={t(oneClickTrading ? 'wtr:DISALBE_ONE_CLICK_TRADING' : 'wtr:ENABLE_ONE_CLICK_TRADING')}
				position="right center"
			>
				<div ref={parentRef}>
					<WtrToggle
						onChange={handleClick}
						icon={oneClickTrading ? ['fas', 'bolt'] : ['fas', 'bolt-slash']}
						isActive={oneClickTrading}
						id="oneClickTradingToggle"
						activeBackgroundStyling={oneClickTrading}
					/>
				</div>
			</WtrTooltip>
			{openActivateModal && (
				<InfoModal
					closeInfoModal={() => setOpenActivateModal(false)}
					arrow={isArabic ? 'right' : 'left'}
					parentRef={parentRef}
					header={
						<div className={styles.modalMessage}>
							<h1>{t('en:ONE_CLICK_TRADING_CONFIG_ACTIVE')}</h1>
						</div>
					}
					footer={
						<>
							<div className={styles.buttonsContainer}>
								<Button
									size="lg"
									label={t('en:NOT_NOW')}
									className={styles.button}
									onClick={handleCancel}
									variant="outline"
									isFullWidth={true}
								/>
								<Button
									size="lg"
									label={t('en:ACTIVATE')}
									className={styles.button}
									onClick={handleActivate}
									variant="primary"
									isFullWidth={true}
								/>
							</div>
						</>
					}
				/>
			)}
		</>
	);
};

export default OneClickTradingToggle;
