import React, { useEffect, useRef } from 'react';

import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './LiveChat.module.scss';
import { useTranslation } from 'react-i18next';
import WtrTooltip from '../WtrTooltip/WtrTooltip';

const Vertical = ({
	isOpen,
	onZDClick,
	onToggle,
	channels,
}: {
	isOpen: boolean;
	onZDClick: () => void;
	onToggle: () => void;
	channels: [any];
}) => {
	const liveChatRef = useRef<HTMLElement>(null);
	const { t } = useTranslation();
	const mouseDown = (event: MouseEvent): void => {
		if (isOpen && liveChatRef.current && !liveChatRef.current.contains(event.target as any)) {
			onToggle();
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', mouseDown);
		return () => document.removeEventListener('mousedown', mouseDown);
	}, []);

	interface tooltipTranslationsProps {
		line: string;
		telegram: string;
		twitter: string;
	}
	const tooltipTranslations = {
		line: t('wtr:CONTACT_US_ON_LINE'),
		telegram: t('wtr:CONTACT_US_ON_TELEGRAM'),
		twitter: t('wtr:CONTACT_US_ON_X'),
	};

	return (
		<div className={styles.container} ref={liveChatRef}>
			<div className={styles.vertical}>
				<button className={styles.expandCollapse} onClick={() => onToggle()}>
					<FontAwesomeIcon className={styles.icon} icon={['fas', isOpen ? 'caret-down' : 'caret-up']} />
				</button>
				<div className={cn(styles.channels, isOpen ? styles.isOpen : '')}>
					{channels.map(({ icon, href }) => (
						<WtrTooltip
							content={tooltipTranslations[icon[1] as keyof tooltipTranslationsProps]}
							position="right center"
						>
							<a target="_blank" rel="noopener noreferrer" href={href} key={icon[1]} onClick={onToggle}>
								<FontAwesomeIcon className={styles.icon} icon={icon} />
							</a>
						</WtrTooltip>
					))}
				</div>

				<WtrTooltip content={t('en:NAVIGATION_LIVECHAT')} position="right center">
					<button className={styles.zendesk} onClick={onZDClick}>
						<FontAwesomeIcon className={styles.icon} icon={['fas', 'comment-alt-dots']} />
					</button>
				</WtrTooltip>
			</div>
		</div>
	);
};

export default Vertical;
