import { HistoryTick, Instrument, PriceQuote } from '../../../gateways/RfpGateway/rfp.types';
import { findCommonMarketItem, getMarketItem } from '../../../utils/functions/calculations';

import { GymTradingAccount } from '../Accounts/GymTradingAccount';

import { GymHistoryManager } from './GymHistoryManager';

/**
 * The TPRefQuotesStore class defines the `sharedInstance` method that lets clients access
 * the unique singleton instance.
 */
class GymRefQuotesStore {
	private static instance: GymRefQuotesStore;
	private refQuotes = new Map<string, HistoryTick[]>();

	/**
	 * The TPRefQuotesStore's constructor should always be private to prevent direct
	 * construction calls with the `new` operator.
	 */
	private constructor() {}

	/**
	 * The static method that controls the access to the singleton instance.
	 */
	public static sharedInstance(): GymRefQuotesStore {
		if (!GymRefQuotesStore.instance) {
			GymRefQuotesStore.instance = new GymRefQuotesStore();
		}
		return GymRefQuotesStore.instance;
	}

	public addTicks(historyTicks: HistoryTick[], reqId: string) {
		this.refQuotes.set(reqId, historyTicks);
	}

	public getPrice(baseCurrency: string, accountCurrency: string, closeTime: number, reqId: string) {
		if (baseCurrency === accountCurrency) {
			return 1.0;
		}

		const key = `${GymHistoryManager.buildRefQuoteKey(reqId)}_${accountCurrency}`;
		const dataItems = this.refQuotes.get(key);
		if (dataItems) {
			const dataItem = this.searchDataItem(dataItems, closeTime);
			if (dataItem) {
				if (baseCurrency === 'JPY') {
					return 1 / dataItem.close;
				}
				return dataItem.close;
			}
		}

		return NaN;
	}

	public getTradingInstrument(account: GymTradingAccount, code: string): Instrument | undefined {
		return account.tradingInstruments?.instruments[code];
	}

	private convertHistoryTickToPriceQuote = (historyTick: HistoryTick) => {
		return {
			a: historyTick.close,
			b: historyTick.close,
			c: historyTick.code,
			f: historyTick.feedId,
			h: historyTick.high,
			l: historyTick.low,
			t: historyTick.openTime,
		} as PriceQuote;
	};

	public getQuotePrices(code: string, reqId: string, closeTime: number): PriceQuote | undefined {
		const key = `${GymHistoryManager.buildRefQuoteKey(reqId)}_${code}`;
		const historyTicks = this.refQuotes.get(key);
		if (historyTicks) {
			const historyTick = this.searchDataItem(historyTicks, closeTime);
			if (historyTick) {
				return this.convertHistoryTickToPriceQuote(historyTick);
			}
		}

		return undefined;
	}

	public convertToBaseCurrency = (
		account: GymTradingAccount,
		quoteCurrency: string,
		feedId: string,
		symbol: string,
		isBuy: boolean,
		pl: number,
		closePrice: number,
		closeTime: number,
		reqId: string
	) => {
		const baseAccountCurrency = account.baseCurrency;
		if (quoteCurrency === baseAccountCurrency) {
			return pl;
		}

		let pair = quoteCurrency + baseAccountCurrency;
		let inverse = false;
		let homeCurrencyConversionRate;

		let info = this.getTradingInstrument(account, pair);

		if (!info) {
			pair = baseAccountCurrency + quoteCurrency;
			info = this.getTradingInstrument(account, pair);
			inverse = true;
		}

		let lastQuoteBaseCcy;
		if (info) {
			lastQuoteBaseCcy = this.getQuotePrices(info.code, reqId, closeTime);
		}

		//no luck...keep digging for a path
		if (!lastQuoteBaseCcy) {
			inverse = false;
			pl = pl / closePrice;

			let mt = getMarketItem(feedId, symbol);
			if (mt) {
				pair = mt.bCcy + baseAccountCurrency;
				info = this.getTradingInstrument(account, pair);

				if (!info) {
					pair = baseAccountCurrency + mt.bCcy;
					info = this.getTradingInstrument(account, pair);
					inverse = true;
				}

				if (!info) {
					let mtPair: any = findCommonMarketItem(quoteCurrency, baseAccountCurrency);
					if (mtPair) {
						pl = pl * closePrice;
						lastQuoteBaseCcy = this.getQuotePrices(mtPair.first.code, reqId, closeTime);

						if (lastQuoteBaseCcy) {
							inverse = !(mtPair.first.bCcy === quoteCurrency);
							if (inverse) {
								homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.b : lastQuoteBaseCcy.a;
								pl = pl / homeCurrencyConversionRate;
							} else {
								homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.a : lastQuoteBaseCcy.b;
								pl = pl * homeCurrencyConversionRate;
							}
							info = this.getTradingInstrument(account, mtPair.second.code);
							inverse = mtPair.second.bCcy === baseAccountCurrency;
						} else {
							return NaN;
						}
					} else {
						return NaN;
					}
				}
			}

			if (!info) {
				return NaN;
			}

			lastQuoteBaseCcy = this.getQuotePrices(info.code, reqId, closeTime);

			if (lastQuoteBaseCcy) {
				if (inverse) {
					homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.b : lastQuoteBaseCcy.a;
					pl = pl / homeCurrencyConversionRate;
				} else {
					homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.a : lastQuoteBaseCcy.b;
					pl = pl * homeCurrencyConversionRate;
				}
			} else {
				return NaN;
			}

			return pl;
		}

		if (inverse) {
			homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.b : lastQuoteBaseCcy.a;
			pl = pl / homeCurrencyConversionRate;
		} else {
			homeCurrencyConversionRate = isBuy ? lastQuoteBaseCcy.a : lastQuoteBaseCcy.b;
			pl = pl * homeCurrencyConversionRate;
		}

		return pl;
	};

	public searchDataItem(a: HistoryTick[], closeTime: number): HistoryTick | undefined {
		let low = 0;
		let high = a.length - 1;
		let mid = 0;

		while (low <= high) {
			mid = parseInt(`${(low + high) / 2}`);
			//mid = (low + high) / 2;
			const midVal = a[mid].closeTime;
			if (midVal < closeTime) low = mid + 1;
			else if (midVal > closeTime) high = mid - 1;
			else return a[mid];
		}

		const index = Math.min(low, high);
		if (index < 0 && a.length > 0) {
			return a[0];
		}
		if (index >= a.length && a.length > 0) {
			return a[a.length - 1];
		}
		return a[Math.min(low, high)];
	}
}

export { GymRefQuotesStore };
