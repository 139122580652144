import { AccountMarketType } from '../../../gateways/RfpGateway/rfp.types';
import authStore, { TradingMode } from '../../../store/authStore';
import { LastLoggedAct } from '../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import { RFP } from '../../../gateways/RfpGateway/rfpConstants';
import useSaveUserPreferences from '../../mutations/useSaveUserPreferences';
import { useContext } from 'react';
import DashboardContext from '../../../contexts/DashboardContext';
import AppContext from '../../../contexts/AppContext';
import { Resolver } from '../../functions/Ioc';
import RfpGateway from '../../../gateways/RfpGateway/RfpGateway';
import { useFillContextsFromPreferences } from '../preferences/useFillContextsFromPreferences';
import tradingAccountStore from '../../../store/tradingAccountStore';
import useSelectedTradingAccount from '../useSelectedTradingAccount';
import { Account } from '../../../gateways/TfboGateway/TfboGateway.types';
import { TradingAccountType } from '../../functions/enums';

const useSwitchAccount = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const rfpGateway = Resolver.resolve(RfpGateway);
	const setTradingMode = authStore.use.setTradingMode();
	const selected = tradingAccountStore.use.selected();
	const setSelected = tradingAccountStore.use.setSelected();
	const setAccountMarketType = tradingAccountStore.use.setAccountMarketType();
	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();
	const selectedTradingAccount = useSelectedTradingAccount();
	const selectedTradingMode = authStore.use.tradingMode();

	const { mutateAsync: savePreferences } = useSaveUserPreferences();
	const fillContextsFromPreferences = useFillContextsFromPreferences();

	const switchAccounts = async (account: Account) => {
		dashboardContext.closeAllOtherTabs();

		if (!account) {
			return;
		}

		if (account.type !== selectedTradingMode.toLowerCase()) {
			setTradingMode(account.type as TradingMode);
		}

		const selectedIndex = dashboardContext.tradingAccount.findIndex((acc) => {
			return acc.accountNumber === account.accountNumber;
		});

		if (selectedIndex !== -1) {
			const selectedAccount = dashboardContext.tradingAccount[selected];
			const newAccount = dashboardContext.tradingAccount[selectedIndex];

			// This seems to be unnecessary and is causing that tradingAccountStore.use.currentWatchList is set tu undefined,
			// then the Gym is failing to fill the simulations list (https://thinkmarkets.atlassian.net/browse/WTR-4925)
			// if (selectedAccount?.accountMarketType !== newAccount?.accountMarketType) {
			// 	debugger
			// if (dashboardContext.mappedWatchlist !== null) {
			// setCurrentWatchList(dashboardContext.mappedWatchlist[0]);
			// }
			// }

			setAccountMarketType({
				isJapanSpread: newAccount?.accountMarketType === AccountMarketType.JapanSpread,
				isJapanSubscription:
					newAccount?.accountMarketType === AccountMarketType.Japan &&
					newAccount?.accountType === TradingAccountType.LIVE,
				isSpreadBetting: newAccount?.accountMarketType === AccountMarketType.SpreadBetting,
			});
			setSelected(selectedIndex);

			if (selectedTradingAccount) {
				rfpGateway.send(RFP.tradingAccountLogout, { acct_id: selectedTradingAccount.id });
			}
		}

		if (appContext.userPreferences) {
			if (account.accountNumber) {
				appContext.userPreferences.user_prefs.platform.lastLoggedAct[
					account.type.toLowerCase() as keyof LastLoggedAct
				] = account.accountNumber.toString();
			}

			fillContextsFromPreferences(appContext.userPreferences, isFundedTrader);
			await savePreferences();
		}
	};

	return [switchAccounts];
};

export default useSwitchAccount;
