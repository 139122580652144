import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cn from 'classnames';

import Routes from '../../../../../../setup/routes';

import { AppComponentType, TradingAccountType } from '../../../../../../utils/functions/enums';
import AppContext, { TTradingMode } from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';

import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';

import accountStatusStore from '../../../../../../store/accountStatusStore';

import { IStatusConfigEnum } from '../../../../../components/Permissions/config.types';

import authStore, { TradingMode } from '../../../../../../store/authStore';
import tradingAccountStore from '../../../../../../store/tradingAccountStore';

import {
	Account,
	TradingAccountType as PlatformAccountType,
} from '../../../../../../gateways/TfboGateway/TfboGateway.types';

import { checkHasSubscriptionOptions } from '../../../../../../utils/functions/subscriptionUtils';
import useSubscriptionInfo from '../../../../../../utils/hooks/useSubscriptionInfo';

import AccountIconAndTier from '../../components/AccountIconAndTier';

import SubscriptionOptions from './SubscriptionOptions';

import { printAccountName } from '../../../../../../utils/functions/getAccountStats';
import useSwitchAccount from '../../../../../../utils/hooks/system/useSwitchAccount';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';

import styles from './TradingAccounts.module.scss';
import { LastLoggedAct } from '../../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import useSaveUserPreferences from '../../../../../../utils/mutations/useSaveUserPreferences';

interface ITradingAccountsProps {
	activeMenuItem: 'live' | 'demo' | TTradingMode;
	setAccountPopupIsOpen: (isOpen: boolean) => void;
}

const TradingAccounts = ({ setAccountPopupIsOpen, activeMenuItem }: ITradingAccountsProps) => {
	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');
	const history = useHistory();
	const [subscriptionInfo] = useSubscriptionInfo();
	const [switchAccounts] = useSwitchAccount();
	const selectedTradingAccount = useSelectedTradingAccount();
	const setTradingMode = authStore.use.setTradingMode();

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const permissions = accountStatusStore.use.permissions();
	const setShowModal = accountStatusStore.use.setShowModal();
	const hasLiveAccount = accountStatusStore.use.hasLiveAccount();
	const selectedTradingMode = authStore.use.tradingMode();
	const addAccount = permissions.addAccount;
	const accountStats = tradingAccountStore.use.accountStats();
	const currentAccountUrl = tradingAccountStore.use.currentAccountUrl();
	const userProfile = authStore.use.userProfile();

	const { mutate: saveUserPreferences } = useSaveUserPreferences();

	const email = appContext.email ?? userProfile.email!;

	const accountsList = useMemo(
		() => accountStats.filter((account) => account.account.type.toLowerCase() === activeMenuItem?.toLowerCase()),
		[activeMenuItem, accountStats]
	);

	const handleSwitchAccounts = (account: Account) => {
		if (selectedTradingAccount?.accountNumber !== account.accountNumber) {
			switchAccounts(account);
		}

		if (account && account.accountNumber) {
			const currentAcc = dashboardContext.rfpServerNames.find(
				(item) => item.ttAccountId === account.accountNumber!.toString()
			);
			if (currentAcc?.rfpDnsName && currentAcc?.rfpDnsName !== currentAccountUrl) {
				const rfpUrlStorage = JSON.parse(localStorage.getItem('rfpUrl') || '{}');
				const rfpUrlsObj = {
					...rfpUrlStorage,
					[email]: {
						url: currentAcc?.rfpDnsName,
						mode: account.type,
					},
				};
				localStorage.setItem('rfpUrl', JSON.stringify(rfpUrlsObj));

				if (account.accountNumber && appContext.userPreferences) {
					appContext.userPreferences.user_prefs.platform.lastLoggedAct[
						account.type.toLowerCase() as keyof LastLoggedAct
					] = account.accountNumber.toString();
				}

				saveUserPreferences();

				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		}

		setAccountPopupIsOpen(false);
	};

	const handleAddAccountClick = () => {
		if (hasNoDemoAccount) {
			// When login in DEMO mode, if there is no account a default one will be created
			// this is achieved by the setTradingMode
			dashboardContext.closeAllOtherTabs();
			if (activeMenuItem !== selectedTradingMode.toLowerCase()) {
				setTradingMode(activeMenuItem.toUpperCase() as TradingMode);
			}
			setAccountPopupIsOpen(false);
			return;
		}

		if (addAccount === IStatusConfigEnum.addLiveAccount || addAccount === IStatusConfigEnum.addDemoAccount) {
			dashboardContext.presentComponentType = AppComponentType.OpenAccount;
			history.push(Routes.trader.openAccount, { from: window.location.pathname });
		} else {
			setShowModal(true);
			appContext.statusModal = true;
		}

		setAccountPopupIsOpen(false);
	};

	const isLiveMenu = activeMenuItem.toLowerCase() === 'live';
	const showAddAccount =
		(!appContext.isJapanAccount &&
			permissions?.addAccount &&
			selectedTradingMode.toLowerCase() === activeMenuItem.toLowerCase()) ||
		(isLiveMenu && !hasLiveAccount);

	// this is a temporary update for https://thinkmarkets.atlassian.net/browse/WTR-5012
	// >>> Temporarily remove the option to onboard on the Subscription account
	// const showAddJapanAccount = appContext.isJapanAccount && isLiveMenu && accountsList.length && accountsList.length < 2;
	// const hasJapanSpread = showAddJapanAccount && !!accountsList.find(({ platformAccountType }) => platformAccountType === PlatformAccountType.JapanSpread);
	const hasJapanSpread = !!accountsList.find(({ platformAccountType }) => platformAccountType === PlatformAccountType.JapanSpread);
	const showAddJapanAccount = appContext.isJapanAccount && isLiveMenu && !hasJapanSpread;

	const hasNoDemoAccount = activeMenuItem.toLowerCase() === 'demo' && accountsList.length === 0;

	return (
		<div className={styles.accountsList}>
			{accountsList.map(({ account, platformAccountType }: any) => {
				const isLiveAccount = account.type === TradingAccountType.LIVE;
				const isSpreadBettingAccount = platformAccountType === PlatformAccountType.SpreadBetting;
				const isJapanSpreadAccount = platformAccountType === PlatformAccountType.JapanSpread;
				const isJapanSubscriptionAccount = appContext.isJapanAccount && isLiveAccount && !isJapanSpreadAccount;
				const hasSubscriptionOptions =
					isJapanSubscriptionAccount && checkHasSubscriptionOptions(subscriptionInfo.status);
				const isCurrentAccount = account.accountNumber.toString() === selectedTradingAccount?.providerAccountId;

				return (
					<div
						key={account.accountNumber}
						className={cn(styles.accountsListItem, styles.noPadding, isCurrentAccount && styles.active)}
					>
						<div className={cn(styles.accountInfo)} onClick={() => handleSwitchAccounts(account)}>
							<span className={cn(styles.icon, isCurrentAccount && styles[account.type.toLowerCase()])}>
								<AccountIconAndTier
									isForJapanSubscriptionAccount={isJapanSubscriptionAccount}
									customCurrency={account.currency.code}
									isNotListOfSelectedTradingMode={selectedTradingMode.toLowerCase() !== activeMenuItem.toLowerCase()}
								/>
							</span>

							<div className={styles.statsContainer}>
								<div className={cn(styles.stats, isCurrentAccount && styles.selected)}>
									<span className={styles[account.type.toLowerCase()]}>
										{t(`en:LOGIN_VIEW_${account.type.toUpperCase()}`)}
									</span>
									&nbsp;
									<span>{account.accountNumber}</span>
								</div>
								<span className={styles.baseCurrency}>
									{printAccountName({
										t,
										isLiveAccount,
										isSpreadBettingAccount,
										isJapanSpreadAccount,
										isJapanSubscriptionAccount,
										defaultName: `${account.currency.code} • ${t('wtr:CFD')}`,
									})}
								</span>
							</div>
						</div>
						{isCurrentAccount && hasSubscriptionOptions && (
							<SubscriptionOptions setAccountPopupIsOpen={setAccountPopupIsOpen} />
						)}
					</div>
				);
			})}

			{(showAddAccount || hasNoDemoAccount || showAddJapanAccount) && (
				<div className={styles.addAccountContainer} onClick={handleAddAccountClick}>
					<FontAwesomeIcon
						icon={['fal', 'plus']}
						className={cn(styles.plusIcon, styles[activeMenuItem.toLowerCase()])}
					/>
					<span
						className={styles.accountType}
						dangerouslySetInnerHTML={{
							__html: tt(`ADD_${activeMenuItem.toUpperCase()}_ACCOUNT`, {
								accountName: showAddJapanAccount
									? hasJapanSpread
										? `${t('wtr:JP_FX_SUBSCRIPTION')} `
										: `${t('wtr:JP_FX_SPREAD')} `
									: '',
								classNameAccountType: cn(styles.accountType, styles[activeMenuItem.toLowerCase()]),
							}),
						}}
					></span>
				</div>
			)}
		</div>
	);
};

export default React.memo(TradingAccounts);
