import React, { FC, useContext } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import AppContext from '../../../../../contexts/AppContext';

import quoteStore from '../../../../../store/QuoteStore/quoteStore';

import { formatNumberWithCommas } from '../../../Dashboard/Watchlist/Instrument/formattedQuoteNumber';

import { MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';

import styles from '../MarketsTable.module.scss';

export enum SellOrBuyType {
  Sell = 'sell',
  Buy = 'buy',
}

interface SellOrBuyButtonProps {
  marketItem: MarketItem;
  type: SellOrBuyType;
}

const SellOrBuyButton: FC<SellOrBuyButtonProps> = ({
  marketItem,
  type
}): JSX.Element => {
  const appContext = useContext(AppContext);
  const languageSettings = appContext.languageSettings;
  const { t } = useTranslation();
  const quote = quoteStore(state => state.quotes[marketItem && marketItem.code]);

  const addCommas = (current: number | string, decimalPrecision: number) => {
    const decPrec = current === 0 ? 0 : decimalPrecision;
    return formatNumberWithCommas(current, decPrec, languageSettings);
  };

  if (!marketItem) {
    return <div/>;
  }

  return (
    <OverlayTrigger
      delay={{ show: 0, hide: 1 }}
      key={`marketsPageSell${marketItem.code}`}
      placement="bottom"
      overlay={
        <Tooltip className="my-tooltip" id={`marketsPageSell${marketItem.code}`}>
          <div className={styles.tooltipTimestamp}>
            <div className={styles.tooltipTitle}>
              {t(`en:${type === SellOrBuyType.Sell ? 'SELL_ACTION' : 'BUY_ACTION'}`)} {marketItem.code.replace('_SB', '')}
            </div>
            <div className={styles.timeUpdate}>
              {quote && quote.currentQuote && quote.currentQuote.t ? moment(quote.currentQuote.t).format('DD MMM YY H:mm:ss') : ''}
            </div>
          </div>
        </Tooltip>
      }
    >
      {
        type === SellOrBuyType.Buy
          ? (
            <div
              className={quote && quote.previousQuote && quote.currentQuote && quote.previousQuote.a > quote.currentQuote.a ? styles.typeButton : styles.testButton}>
              <div className={styles.typeValue} id="sellButton">
                {quote && quote.currentQuote ? addCommas(quote.currentQuote.a, marketItem.decPrec) : 0}
              </div>
            </div>
          )
          : (
            <div
              className={quote && quote.previousQuote && quote.currentQuote && quote.previousQuote.b > quote.currentQuote.b ? styles.typeButton : styles.testButton}>
              <div className={styles.typeValue} id="buyButton">
                {quote && quote.currentQuote ? addCommas(quote.currentQuote.b, marketItem.decPrec) : 0}
              </div>
            </div>
          )
      }
    </OverlayTrigger>
  );
};

export default SellOrBuyButton;