import React, { useContext, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import WtrPopup from '../../../../../components/WtrPopup/WtrPopup';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { getInstrumentDetails } from '../../../../../../utils/functions/calculations';
import useSelectedTradingAccount from '../../../../../../utils/hooks/useSelectedTradingAccount';
import RfpGatewayContext from '../../../../../../contexts/RfpGatewayContext';
import { hidePopup } from '../helpers';
import { TicketLayout, TradingPositionLimitType } from '../../../../../../utils/functions/enums';
import { TradingPosition, TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';
import pipStore from '../../../../../../store/PipsStore/pipStore';
import orderStore from '../../../../../../store/OrderStore/orderStore';
import tradingViewStore from '../../../../../../store/tradingViewStore';
import { forceCloseModal } from '../../../../../../utils/hooks/useForceCloseModal';
import { GymTradingPosition } from '../../../../../../pages/TradersGym/Positions/GymTradingPosition';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';
import gymRemoveModal from './GymRemoveModal';
import GymRemoveModal from './GymRemoveModal';

interface EditIcon {
	info: any;
	gym?: boolean;
}

const EditIcon = ({ info, gym = false }: EditIcon) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const isArabic = appContext.isArabic;
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const detailedInformation = dashboardContext.detailedInformation;
	const rfpGatewayContext = useContext(RfpGatewayContext);

	const stopLossPips = pipStore.use.stopLoss();
	const takeProfitPips = pipStore.use.takeProfit();
	const fillStoreFromPosition = orderStore.use.fillStoreFromPosition();
	const setTradeProps = orderStore.use.setTradeProps();

	const activeTradingAccount = useSelectedTradingAccount();
	const tradingPositions = dashboardContext.getTradingPositions();

	const { t } = useTranslation();

	const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);

	const record = info.row.original;

	const iconStyle = {
		fontSize: '20px',
		margin: 0,
	};

	const openPositionInformation = (code: string) => {
		hidePopup(isArabic);
		if (detailedInformation) {
			const instrumentDetail = getInstrumentDetails(
				detailedInformation,
				activeTradingAccount ? [activeTradingAccount] : [],
				code
			);

			if (instrumentDetail) {
				let marketItem = rfpGatewayContext?.getMarketItem(instrumentDetail.code);
				if (marketItem) {
					const positions: any = tradingPositions.filter(
						(t) =>
							t.code === record.code &&
							t.state === TradingPositionState.open &&
							t.aId === activeTradingAccount?.id &&
							(record.isGroupRow || record.posId === t.posId)
					);

					dashboardContext.selectedPosition = positions;
					dashboardContext.selectedInstrument = marketItem;
				}
			}

			dashboardContext.closeAllOtherTabs();
			dashboardContext.toggleAccordionMenu = '1';
			dashboardContext.showOrderInformation = true;
		}
	};

	const openMarketInformation = (code: string) => {
		hidePopup(isArabic);
		if (detailedInformation) {
			const instrumentDetail = getInstrumentDetails(
				detailedInformation,
				activeTradingAccount ? [activeTradingAccount] : [],
				code
			);
			if (instrumentDetail) {
				let marketItem = rfpGatewayContext?.getMarketItem(instrumentDetail.code);
				if (marketItem) {
					const positions: any = tradingPositions.filter(
						(t) =>
							t.code === record.code &&
							t.state === TradingPositionState.open &&
							t.aId === activeTradingAccount?.id &&
							(record.isGroupRow || record.posId === t.posId)
					);

					dashboardContext.selectedPosition = positions;
					dashboardContext.selectedInstrument = marketItem;
				}
			}

			dashboardContext.closeAllOtherTabs();
			dashboardContext.toggleAccordionMenu = '0';
			dashboardContext.showOrderInformation = true;
		}
	};

	const openTicket = (e: any) => {
		hidePopup(isArabic);
		dashboardContext.closeAllOtherTabs();

		const name = e.column.id;
		const record = e.row.original;

		if (record) {
			const positions: any = tradingPositions.filter(
				(t) =>
					t.code === record.code &&
					t.state === TradingPositionState.open &&
					t.aId === activeTradingAccount?.id &&
					(record.isGroupRow || record.posId === t.posId)
			);

			dashboardContext.selectedPosition = positions;
			dashboardContext.selectedInstrument = record.position.marketItem;
		}

		let limitsType: TradingPositionLimitType[] = [];
		if (name === TradingPositionLimitType.StopLoss) {
			limitsType.push(TradingPositionLimitType.StopLoss);
		} else if (name === TradingPositionLimitType.TakeProfit) {
			limitsType.push(TradingPositionLimitType.TakeProfit);
		} else if (name !== 'NaN' && record.TakeProfit !== 'NaN') {
			limitsType.push(TradingPositionLimitType.TakeProfit, TradingPositionLimitType.StopLoss);
		}

		fillStoreFromPosition(record.position, limitsType, true, { takeProfitPips, stopLossPips });

		setTradeProps({
			isPreservedValue: true,
		});

		if (ticketLayout === TicketLayout.Dock) {
			dashboardContext.showOrderTicket = true;

			if (name === TradingPositionLimitType.StopLoss || name === TradingPositionLimitType.TakeProfit) {
				dashboardContext.gridButton =
					name === TradingPositionLimitType.StopLoss
						? TradingPositionLimitType.StopLoss
						: TradingPositionLimitType.TakeProfit;
			} else if (name) {
				dashboardContext.gridButton = name;
			} else {
				dashboardContext.gridButton = 'Menu Item';
			}
		} else {
			dashboardContext.newOrderModalToggle = {
				orderTicket: true,
				confirmOrder: false,
			};

			if (name === TradingPositionLimitType.StopLoss || name === TradingPositionLimitType.TakeProfit) {
				dashboardContext.gridButton =
					name === TradingPositionLimitType.StopLoss
						? TradingPositionLimitType.StopLoss
						: TradingPositionLimitType.TakeProfit;
			} else if (name) {
				dashboardContext.gridButton = name;
			} else {
				dashboardContext.gridButton = 'Menu Item';
			}
		}
	};

	const handleRemoveButtonClick = () => {
		setOpenRemoveModal(true);
		hidePopup(isArabic);
	};

	return (
		<>
			<GymRemoveModal openRemoveModal={openRemoveModal} setOpenRemoveModal={setOpenRemoveModal} record={record} />
			<OverlayTrigger
				delay={{ show: 750, hide: 0 }}
				key={`more${record.code}`}
				placement={isArabic ? 'right' : 'left'}
				overlay={
					<Tooltip className="my-tooltip" id={`more${record.code}`}>
						{t('en:NAVIGATION_MORE')}
					</Tooltip>
				}
			>
				<div key={`editPopup${record.code}`}>
					<WtrPopup
						className={styles.Popup}
						content={
							<>
								<div className={styles.PopupContainer} onClick={() => openPositionInformation(record.code)}>
									<FontAwesomeIcon icon={['fas', 'info-circle']} size="1x" />
									<div>{t('en:TRADES_POSITION_DETAILS')}</div>
								</div>
								<div
									className={styles.PopupContainer}
									onClick={() => {
										openMarketInformation(record.code);
									}}
								>
									<FontAwesomeIcon icon={['fas', 'info']} size="1x" className={styles.infoIcon} />
									<div>{t('wtr:MARKET_INFO')}</div>
								</div>
								{!record.isGroupRow && (
									<div className={styles.PopupContainer} onClick={() => openTicket(info)}>
										<FontAwesomeIcon icon={['fas', 'layer-plus']} size="1x" />
										<div dangerouslySetInnerHTML={{ __html: t('wtr:SET_TP_SL') }}></div>
									</div>
								)}
								{gym && (
									<div
										className={styles.PopupContainer}
										onClick={() => {
											handleRemoveButtonClick();
										}}
									>
										<FontAwesomeIcon icon={['fas', 'trash']} size="1x" />
										<div>{t('en:REMOVE')}</div>
									</div>
								)}
							</>
						}
						on={['click']}
						pinned
						position={isArabic ? 'top left' : 'top right'}
						trigger={
							<div className={styles.triggerContainer}>
								<FontAwesomeIcon icon={['fas', 'ellipsis-v']} style={iconStyle} className={styles.editTheme} />
							</div>
						}
					/>
				</div>
			</OverlayTrigger>
		</>
	);
};

export default EditIcon;
