import { ENVIRONMENT_NAME } from '../../../../setup/config';

export type TLanguageOptions = {
	shortText?: string;
	value: string;
	disabled?: boolean;
	rfpLang: string;
	captchaLang: string;
	signalsLang: string;
	calendarLang: string;
	tradingView: string;
};

const hasBrackets = ENVIRONMENT_NAME !== 'production' && ENVIRONMENT_NAME !== 'preview-production';

export enum LanguageCodes {
	ENGLISH = 'en',
	CHINESE_SIMPLIFIED = 'zh-Hans',
	CHINESE_TRADITIONAL = 'zh-Hant',
	DEUTSCH = 'de',
	JAPANESE = 'ja',
	VIETNAMESE = 'vi',
	THAI = 'th',
	PORTUGUESE = 'pt-BR',
	ARABIC = 'ar-SA',
	ITALIAN = 'it',
	SPANISH = 'es',
}

export const languageOptions: TLanguageOptions[] = [
	{
		shortText: 'English',
		value: LanguageCodes.ENGLISH,
		disabled: false,
		rfpLang: 'en',
		captchaLang: 'en',
		signalsLang: 'en-gb',
		calendarLang: 'en',
		tradingView: 'en',
	},
	{
		shortText: '简体中文', // Chinese Simplified
		value: LanguageCodes.CHINESE_SIMPLIFIED,
		disabled: false,
		rfpLang: 'cn',
		captchaLang: 'zh-CN',
		signalsLang: 'zh-cn',
		calendarLang: 'cs',
		tradingView: 'zh',
	},
	{
		shortText: '繁體中文', // Chinese Traditional
		value: LanguageCodes.CHINESE_TRADITIONAL,
		disabled: false,
		rfpLang: 'ct',
		captchaLang: 'zh-TW',
		signalsLang: 'zh-cht',
		calendarLang: 'cs',
		tradingView: 'zh_TW',
	},
	{
		shortText: 'Deutsch', // German
		value: LanguageCodes.DEUTSCH,
		disabled: false,
		rfpLang: 'de',
		captchaLang: 'de',
		signalsLang: 'de-de',
		calendarLang: 'de',
		tradingView: 'de',
	},
	{
		shortText: '日本語', // Japanese
		value: LanguageCodes.JAPANESE,
		disabled: false,
		rfpLang: 'ja',
		captchaLang: 'ja',
		signalsLang: 'ja-jp',
		calendarLang: 'ja',
		tradingView: 'ja',
	},
	{
		shortText: 'Tiếng Việt', // Vietnamese
		value: LanguageCodes.VIETNAMESE,
		disabled: false,
		rfpLang: 'vi',
		captchaLang: 'vi',
		signalsLang: 'vi-vn',
		calendarLang: 'vi',
		tradingView: 'vi',
	},
	{
		shortText: 'ไทย', // Thai
		value: LanguageCodes.THAI,
		disabled: false,
		rfpLang: 'th',
		captchaLang: 'th',
		signalsLang: 'th-th',
		calendarLang: 'vi',
		tradingView: 'th',
	},
	{
		shortText: 'Português', // Portguese
		value: LanguageCodes.PORTUGUESE,
		disabled: false,
		rfpLang: 'pt',
		captchaLang: 'pt',
		signalsLang: 'pt-br',
		calendarLang: 'pt',
		tradingView: 'pt',
	},
	{
		shortText: 'العربية', // Arabic
		value: LanguageCodes.ARABIC,
		disabled: false,
		rfpLang: 'ar_sa',
		captchaLang: 'ar',
		signalsLang: 'ar-ae',
		calendarLang: 'ar',
		tradingView: 'ar',
	},
	{
		shortText: 'Italiano', // Italian
		value: LanguageCodes.ITALIAN,
		disabled: false,
		rfpLang: 'it',
		captchaLang: 'it',
		signalsLang: 'it-it',
		calendarLang: 'it',
		tradingView: 'it',
	},
	{
		shortText: 'Español', // Spanish
		value: LanguageCodes.SPANISH,
		disabled: false,
		rfpLang: 'es',
		captchaLang: 'es',
		signalsLang: 'es-es',
		calendarLang: 'es',
		tradingView: 'es',
	},
].map((lang) => {
	if (hasBrackets) {
		lang.shortText += ` (${lang.value})`;
	}
	return lang;
});

export const getEnabledLangOptions = (allowedLanguages: string[] = []): TLanguageOptions[] => {
	const enabledLangOptions = languageOptions.filter((languageOption: TLanguageOptions) => {
		if (languageOption.disabled) {
			return false;
		}

		if (allowedLanguages.length && !allowedLanguages.includes(languageOption.value)) {
			return false;
		}

		return true;
	});

	return enabledLangOptions;
};

export const getInactivityLogoutOptions = (t: (string: string) => string) => [
	{ text: t('wtr:15_Minutes'), value: '900' },
	{ text: t('wtr:60_Minutes'), value: '3600' },
	{ text: t('wtr:360_Minutes'), value: '21600' },
];

export const rfpLangMap = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.rfpLang }),
	{} as { string: string }
);
export type RfpLangMap = keyof typeof rfpLangMap;
export const captchaLangMap = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.captchaLang }),
	{} as { string: string }
);
export type CaptchaLangMap = keyof typeof captchaLangMap;
export const signalsLangMap: { string: string } = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.signalsLang }),
	{} as { string: string }
);
export type SignalsLangMap = keyof typeof signalsLangMap;
export const calendarLangMap: { string: string } = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.calendarLang }),
	{} as { string: string }
);
export type CalendarLangMap = keyof typeof calendarLangMap;
export const tradingViewLangMap: { string: string } = getEnabledLangOptions().reduce(
	(acc, current) => ({ ...acc, [current.value]: current.tradingView }),
	{} as { string: string }
);
export type TradingViewLangMap = keyof typeof tradingViewLangMap;
