import { create } from 'zustand';

import createSelectors from '../createSelectors';

import { PriceQuote } from '../../gateways/RfpGateway/rfp.types';

export type QuoteState = {
  quotes: Record<string, { previousQuote: PriceQuote; currentQuote: PriceQuote }>;
  setQuote: (quote: PriceQuote) => void;
  maxVolatility: number;
  setMaxVolatility: (value: number) => void;
};

const quoteStore = create<QuoteState>((set) => ({
  quotes: {},
  setQuote: (quote: PriceQuote) => {
    set((state) => ({
      quotes: {
        ...state.quotes,
        [quote.c]: {
          previousQuote: state.quotes[quote.c]?.currentQuote || quote,
          currentQuote: quote
        }
      }
    }));
  },
  maxVolatility: 1,
  setMaxVolatility: (value: number) => {
    set((state) => ({
      ...state,
      maxVolatility: value
    }));
  }
}));

export default createSelectors(quoteStore);
