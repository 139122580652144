import React, { useState, useRef, useContext, useEffect } from 'react';

import cn from 'classnames';

import Skeleton from 'react-loading-skeleton';
import { NumericFormat } from 'react-number-format';

import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';

import { isCommaSeparator } from '../../../../Watchlist/Instrument/formattedQuoteNumber';
import AppContext from '../../../../../../../contexts/AppContext';

import { ApplicationStatus } from '../../../../../../../utils/functions/enums';

import accountStatusStore, { StatusStore } from '../../../../../../../store/accountStatusStore';
import authStore, { AuthStore } from '../../../../../../../store/authStore';

import styles from './OneClickTradeDropdown.module.scss';
import tradingAccountStore from '../../../../../../../store/tradingAccountStore';

interface OneClickTradeDropdownProps {
	formattedQuantity: string | null | 0;
	quantityValues: string[] | undefined;
	handleQuantityChange: (value: string) => void;
	handleInputChange: (e: any) => void;
	wrongQuantityError: string;
}

const OneClickTradeDropdown = ({
	quantityValues,
	handleQuantityChange,
	formattedQuantity,
	handleInputChange,
	wrongQuantityError,
}: OneClickTradeDropdownProps) => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const hasAnyAccount = accountStatusStore((state: StatusStore) => state.hasAnyAccount);
	const status = accountStatusStore((state: StatusStore) => state.status);
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const dropdownStyle = wrongQuantityError ? styles.dropdownError : styles.dropdown;

	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);

	const skeletonBaseColor = 'var(--gray-10)';
	const skeletonHighlightColor = 'var(--gray-5)';

	const dropdownRef = useRef<HTMLDivElement>(null);
	const dropdownOptionsRef = useRef<HTMLDivElement>(null);

	const closeOpenDropdown = (e: any) => {
		if (
			dropdownOptionsRef.current &&
			!dropdownOptionsRef.current.contains(e.target) &&
			!dropdownRef.current?.contains(e.target)
		) {
			setDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', closeOpenDropdown);
		return () => document.removeEventListener('click', closeOpenDropdown);
	}, []);

	const toggleDropdownOpen = (e: any) => {
		e.stopPropagation();
		setDropdownOpen((dropdownOpen) => !dropdownOpen);
	};

	return (
		<div
			ref={dropdownRef}
			className={dropdownOpen ? cn(dropdownStyle, styles.active) : dropdownStyle}
			onClick={toggleDropdownOpen}
		>
			{formattedQuantity !== undefined ? (
				<NumericFormat
					type="text"
					thousandSeparator={isCommaSeparator(appContext.languageSettings) ? '.' : ','}
					decimalSeparator={isCommaSeparator(appContext.languageSettings) ? ',' : '.'}
					prefix={isSpreadBettingAccount ? `£ ` : ''}
					suffix={isSpreadBettingAccount ? ` ${t('en:POINT')}` : ''}
					allowNegative={false}
					value={formattedQuantity || ''}
					onChange={(e: any) => {
						handleInputChange(e);
					}}
					onClick={(e: any) => e.stopPropagation()}
					onFocus={() => setDropdownOpen(false)}
				/>
			) : // Show empty input for empty account info
			isLiveMode && hasAnyAccount && status !== ApplicationStatus.APPROVED ? (
				<input type="text" readOnly />
			) : (
				<div className={styles.inputSkeleton}>
					<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} />
				</div>
			)}

			<div ref={dropdownOptionsRef} className={styles.options}>
				{quantityValues ? (
					quantityValues.map((value, key) => {
						return (
							<div key={key} className={styles.option} onClick={() => handleQuantityChange(value)}>
								{isSpreadBettingAccount && <span className={styles.pound}>£</span>}
								{value}
								{isSpreadBettingAccount && <span className={styles.points}> £/Point</span>}
							</div>
						);
					})
				) : (
					<div className={styles.option}>{'N/A'}</div>
				)}
			</div>
		</div>
	);
};

export default OneClickTradeDropdown;
