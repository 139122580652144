import { WindowContextProvider } from '../../contexts/WindowContext';

export const closeChildWindows = (context: WindowContextProvider) => {
	const childWindows = context.childWindows;
	if (childWindows.size > 0) {
		for (let child of childWindows.entries()) {
			child.forEach((childWindow: Window | null) => {
				if (childWindow !== null && (childWindow.name.includes('account') || childWindow.name.includes('charts'))) {
					childWindow.close();
				}
			});
		}
	}
};
