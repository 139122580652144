import React from 'react';

import {MarketItem} from '../../../../../gateways/RfpGateway/rfp.types';

// TODO: - Refactor some of the formattedNumber logic. Use a separate component and Number Formatter...
export const formattedNumber = (
	num: number | string,
	marketItem: MarketItem | { decPrec: number; marketItemInfo?: any } | undefined | null,
	isSpreadBettingAccount: boolean,
	order?: any,
	gridChartMenu?: any,
	lang?: string,
	instrumentColorTradeBoard?: string
) => {
    const decPrec = marketItem?.decPrec ?? 0;

    const str = (+num).toFixed(decPrec).toString();
    const language = lang ?? localStorage.getItem('language') ?? 'en';

    // Styling of span elements
    const spanElementStyles = {
        orderTicketBigSymbol: {fontSize: 25},
        orderTicketSmallSymbol: {},
        gridChartMenuBigSymbol: {fontSize: 20, display: 'inline-block', width: 11.5},
        gridChartMenuSmallSymbol: {display: 'inline-block', width: 8},
        gridChartMenuDot: {display: 'inline-block', width: 4},
        watchlistBigSymbolLongNumber: instrumentColorTradeBoard
            ? {fontSize: 28, color: instrumentColorTradeBoard}
            : {fontSize: 14},
        watchlistBigSymbolShortNumber: instrumentColorTradeBoard
            ? {fontSize: 30, color: instrumentColorTradeBoard}
            : {fontSize: 19},
        watchlistSmallSymbolLongNumber: instrumentColorTradeBoard
            ? {fontSize: 18, color: instrumentColorTradeBoard}
            : {fontSize: 11},
        watchlistSmallSymbolShortNumber: instrumentColorTradeBoard
            ? {fontSize: 20, color: instrumentColorTradeBoard}
            : {},
    };

    //  Maximum length for short bid/ask numbers used to apply different styling for bigger numbers
    const maxBidAskNumberLength = 7;

    //Get comma or dot depending on how the number separates int from decimals
    //Need to get country specific separators so whole numbers are not defaulting to period on line 21
    const separatorIndex = str.lastIndexOf('.' || ',');
    const separator = isCommaSeparator(language) ? ',' : '.';
    const numArray = str.split('');

    const decimalFormat = (firstNum: number, secondNum: number) => {
        let formattedArr: any = [];
        for (let i = 0; i <= numArray.length; i++) {
            if (firstNum === i || secondNum === i) {
                formattedArr.push(
                    <span
                        key={i}
                        style={
                            (order && spanElementStyles.orderTicketBigSymbol) ||
                            (gridChartMenu && spanElementStyles.gridChartMenuBigSymbol) ||
                            (!order &&
                                !gridChartMenu &&
                                (numArray.length > maxBidAskNumberLength
                                    ? spanElementStyles.watchlistBigSymbolLongNumber
                                    : spanElementStyles.watchlistBigSymbolShortNumber))
                        }
                    >
						{numArray[i]}
					</span>
                );
            } else {
                let value = numArray[i];
                if (isCommaSeparator(language) && value === '.') {
                    value = ',';
                }
                formattedArr.push(
                    <span
                        key={i}
                        style={
                            (order && spanElementStyles.orderTicketSmallSymbol) ||
                            (gridChartMenu &&
                                (numArray[i] === '.'
                                    ? spanElementStyles.gridChartMenuDot
                                    : spanElementStyles.gridChartMenuSmallSymbol)) ||
                            (!order &&
                                !gridChartMenu &&
                                (numArray.length > maxBidAskNumberLength
                                    ? spanElementStyles.watchlistSmallSymbolLongNumber
                                    : spanElementStyles.watchlistSmallSymbolShortNumber))
                        }
                    >
						{value}
					</span>
				);
			}
		}

		///Add commas
		let numDecimals = 0;
		if (separatorIndex > 3) {
			let numPastDecimal = 0;
			for (let i = 0; i < separatorIndex; i++) {
				const thirdDigit = separatorIndex - numPastDecimal === 3 - numDecimals;
				if (thirdDigit) {
					formattedArr.splice(
						i,
						0,
						<span style={instrumentColorTradeBoard ? { color: instrumentColorTradeBoard } : {}} key={`d${i}`}>
							{separator === '.' ? ',' : '.'}
						</span>
					);
					numPastDecimal = 0;
					numDecimals++;
				} else {
					numPastDecimal++;
				}
			}
		}

		return gridChartMenu && language === 'ar-SA' ? (
			<div style={{ direction: 'ltr' }}>{formattedArr}</div>
		) : (
			<div>{formattedArr}</div>
		);
	};

	let start;
	let addedLength = 0;

	if (marketItem?.marketItemInfo) {
		start = separatorIndex + +marketItem.marketItemInfo.formatPosition;
		addedLength = +marketItem.marketItemInfo.formatLength - 1;
	} else if (isSpreadBettingAccount) {
		start = separatorIndex + (decPrec <= 3 ? 1 : 3);
		if (decPrec > 1) {
			start++;
		}
	} else {
		start = separatorIndex + (decPrec <= 3 ? 1 : 3);
		addedLength = decPrec === 1 ? 0 : 1;
	}

    return decimalFormat(start, start + addedLength);
};

export const formatNumberWithCommas = (num: number | string, decPrec: number, language: string) => {
    return language ? localizeNumber(+num, decPrec, language) : num.toString();
};

export const localizeNumber = (num: number, decPrec: number, language: string) => {
    if (language === 'ar-SA') language = 'en';
    return new Intl.NumberFormat(language ?? 'en-US', {minimumFractionDigits: decPrec}).format(+num.toFixed(decPrec));
};

export const isCommaSeparator = (language: string) => {
    return ['de', 'vi', 'pt-BR', 'it', 'es'].includes(language);
};

export const localizeDecimalSeparator = (value: string, language: string) => {
    return isCommaSeparator(language) ? value.replace('.', ',') : value;
};

export const replaceAllThousandSeparators = (value: string, language?: string) => {
    language = language ? language : localStorage.getItem('language') ?? 'en';
    const separator = isCommaSeparator(language) ? '.' : ',';
    return value.replaceAll(separator, '');
};

export const revertToTraditionalNumberFormat = (value: string, language: string) => {
    const separator = isCommaSeparator(language) ? '.' : ',';
    value = value.replaceAll(separator, '');
    return isCommaSeparator(language) ? value.replace(',', '.') : value;
};
