import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps } from 'semantic-ui-react';

import cn from 'classnames';

import useShortTranslation from '../../../../../../utils/hooks/useShortTranslation';

import {
	getEnabledLangOptions,
	getInactivityLogoutOptions,
} from '../../../Settings/settingsOptions';
import { useCountryPrivacyPolicy } from '../../../../../../utils/hooks/useCountryPrivacyPolicy';
import { isPreviewProduction, isProduction, versionNumber } from '../../../../../../setup/config';
import WtrDropdown from '../../../../../components/WtrDropdown/WtrDropdown';
import LangSelect from '../../../../../components/LangSelect/LangSelect';

import authStore from '../../../../../../store/authStore';

import styles from './SettingsModule.module.scss';
import MFAManagement from './MFAManagement';

interface platformSettingsProps {
	handleUpdateSettings: (key: any, value: any) => void;
	settings: any;
	setOpenSettings: (v: boolean) => void;
}

const PlatformSettings: React.FC<platformSettingsProps> = ({ handleUpdateSettings, settings, setOpenSettings }) => {
	const tt = useShortTranslation('wtr:');
	const { t } = useTranslation();
	const privacyPolicyLink = useCountryPrivacyPolicy();

	const isJapanAccount = authStore.use.isJapanAccount();
	// const isMFAEnabled = authStore((store) => store.isMFAEnabled)

	const enabledLangOptions = getEnabledLangOptions(
		// Only on PROD: only Japanese is shown in language settings dropdown
		(isProduction || isPreviewProduction) && isJapanAccount ? ['ja'] : []
	);

	const handleLanguage = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
		handleUpdateSettings('language', value as string);
	};

	const handleThemeSwitch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
		handleUpdateSettings('theme', value);
	};

	const handleInactivityLogout = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
		const intactivityValue = parseInt(`${value}`);
		if (!isNaN(intactivityValue) && intactivityValue > 0) {
			handleUpdateSettings('inactivityLogout', intactivityValue);
		}
	};

	return (
		<>
			<div className={styles.platformTab}>
				<div>{t('en:LANGUAGE')}</div>
				<div className={styles.inactivity}>
					<LangSelect
						defaultLang={settings.language}
						onChange={handleLanguage}
						className={styles.dropdownMenu}
						selection={true}
						disabled={enabledLangOptions.length === 1}
					/>
				</div>
			</div>

			<div className={styles.platformTab}>
				<div>{t('en:THEME')}</div>
				<div className={styles.checkContainer}>
					<label className={styles.container}>
						<input
							value="Light"
							type="checkbox"
							checked={settings.theme === 'Light'}
							onChange={handleThemeSwitch}
						></input>
						<span className={styles.redCheck}></span>
					</label>

					<label className={styles.themeLabel}>{t('en:APP_THEME_LIGHT')}</label>
					<label className={styles.container}>
						<input
							value="Dark"
							type="checkbox"
							checked={settings.theme === 'Dark'}
							onChange={handleThemeSwitch}
						></input>
						<span className={styles.redCheck}></span>
					</label>
					<label className={styles.themeLabel}>{t('en:APP_THEME_DARK')}</label>
				</div>
			</div>

			<div className={styles.platformTab}>
				<div>{tt('INACTIVITY_LOGOUT')}</div>
				<div className={styles.inactivity}>
					<WtrDropdown
						selection
						options={getInactivityLogoutOptions(t)}
						defaultValue={settings.inactivityLogout.toString()}
						className={styles.dropdownMenu}
						onChange={handleInactivityLogout}
					/>
				</div>
			</div>

			<div className={styles.platformTab}>
				<div>{t('wtr:TWO_FACTOR_AUTHENTICATION')}</div>
				<div className={styles.twoStep}>
					<MFAManagement setOpenSettings={setOpenSettings} />
				</div>
			</div>

			<div className={styles.platform}>
				<a className={cn(styles.link)} href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
					{t('en:PRIVACY_POLICY')}
				</a>
			</div>

			<div className={styles.versionNumber}>
				{t('wtr:CURRENT_VERSION')} {versionNumber}
			</div>
		</>
	);
};

export default PlatformSettings;
