import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../../../../../contexts/AppContext';

import DashboardContext from '../../../../../../contexts/DashboardContext';
import { TradersGymContext, TradersGymContextType } from '../../../../../../pages/TradersGym/TradersGymContext';

import WtrSlider from '../../../../../../shared/WtrSlider/WtrSlider';
import usePlayNotificationSound from '../../../../../../utils/hooks/usePlayNotificationSound';

import tradingAccountStore from '../../../../../../store/tradingAccountStore';

import styles from './SettingsModule.module.scss';

interface tradingSettingsProps {
	handleUpdateSettings: (key: any, value: any) => void;
	setSettings: (settings: any) => void;
	settings: any;
}

const TradingSettings: React.FC<tradingSettingsProps> = ({ handleUpdateSettings, settings, setSettings }) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const playSound = usePlayNotificationSound();
	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;
	const isTradersGymActive = tradersGymContext.isActive;
	const isArabic = appContext.isArabic;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const [volumeScale, setVolumeScale] = useState<number>(
		settings.notificationSoundVolume || dashboardContext.notificationSoundVolume
	);
	const [soundMuted, setSoundMuted] = useState<boolean>(
		settings.notificationSoundMuted === undefined
			? dashboardContext.notificationSoundMuted
			: settings.notificationSoundMuted
	);

	const handleQuantityTypeChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
		handleUpdateSettings('quantityType', value);
	};

	const handleTfcEnabledChange = () => {
		handleUpdateSettings('tradeFromChartEnabled', !settings.tradeFromChartEnabled);
	};

	const handleOneClickTradingChange = () => {
		handleUpdateSettings('oneClickTrading', !settings.oneClickTrading);
	};

	const handleSliderAfterChange = (value: number) => {
		const isMuted = value > 0 ? false : true;
		setSoundMuted(isMuted);
		setVolumeScale(value);

		setSettings({ ...settings, notificationSoundVolume: value, notificationSoundMuted: isMuted });
		playSound(isMuted, value);
	};

	const handleSliderChange = (value: number) => {
		const isMuted = value > 0 ? false : true;
		setSoundMuted(isMuted);
		setVolumeScale(value);
	};

	const handleChangeMute = () => {
		const newMuted = !soundMuted;
		setSoundMuted(newMuted);
		handleUpdateSettings('notificationSoundMuted', newMuted);

		playSound(newMuted, volumeScale);
	};

	return (
		<>
			{!appContext.isJapanAccount && !isSpreadBettingAccount && (
				<div className={styles.platform}>
					<div>{t('en:QUANTITY_TYPE')}</div>
					<div className={styles.checkContainer}>
						<label className={styles.container}>
							<input
								type="checkbox"
								checked={settings.quantityType === 'Amount'}
								value="Amount"
								onChange={handleQuantityTypeChange}
							></input>
							<span className={styles.redCheck}></span>
						</label>
						<div>
							<label>{t('en:QT_AMOUNT')}</label>
						</div>
						&nbsp;&nbsp;&nbsp;
						<label className={styles.container}>
							<input
								type="checkbox"
								checked={settings.quantityType === 'Lots'}
								value="Lots"
								onChange={handleQuantityTypeChange}
							></input>
							<span className={styles.redCheck}></span>
						</label>
						<div>
							<label>{t('en:QT_LOTS')}</label>
						</div>
					</div>
				</div>
			)}
			<div className={styles.platform}>
				<div>{t('wtr:CHARTIQ_CHART_TRADE')}</div>
				<div className={styles.checkContainer}>
					<div className={styles.toggleButton}>
						<Radio toggle checked={settings.tradeFromChartEnabled} onChange={handleTfcEnabledChange} />
					</div>
				</div>
			</div>

			{!isTradersGymActive && (
				<div className={styles.platform}>
					<div className={styles.titleContainer}>
						<div>{t('wtr:ONE_CLICK_TRADING')}</div>
						{settings.oneClickTrading && !dashboardContext.oneClickTrading && (
							<div className={styles.activateMessage}>{t('en:ONE_CLICK_TRADING_CONFIG_ACTIVE')}</div>
						)}
					</div>
					<div className={styles.checkContainer}>
						<div className={styles.toggleButton}>
							<Radio toggle checked={settings.oneClickTrading} onChange={handleOneClickTradingChange} />
						</div>
					</div>
				</div>
			)}
			<div className={styles.platform}>
				<div className={styles.titleContainer}>{t('wtr:NOTIFICATION_SOUND_VOLUME')}</div>
				<div className={styles.rangeContainer}>
					<div className={styles.iconContainer} onClick={() => handleChangeMute()}>
						<FontAwesomeIcon icon={['fas', soundMuted === true ? 'volume-slash' : 'volume']} />
					</div>

					<div className={styles.slider}>
						<WtrSlider
							reverse={isArabic}
							max={100}
							value={soundMuted === true ? 0 : volumeScale}
							onChange={handleSliderChange}
							onAfterChange={handleSliderAfterChange}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default TradingSettings;
