import { useState, useEffect, useCallback } from 'react';

interface ResizableContainersWidthProps {
	resizableContainersWidthRestriction: (panelName: 'watchlist' | 'tradeTicket') => number | string;
}
const useResizableContainersWidth = (hasModal: boolean): ResizableContainersWidthProps => {
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	useEffect(() => {
		const handleWindowResize = () => setWindowWidth(window.innerWidth);

		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	const resizableContainersWidthRestriction = useCallback(
		(panelName: 'watchlist' | 'tradeTicket') => {
			if (panelName === 'watchlist') {
				if (windowWidth < 1240) return 330;
				if (windowWidth >= 1240) return hasModal ? '33%' : '45%';
			} else if (panelName === 'tradeTicket') {
				if (windowWidth < 1240) return 370;
				if (windowWidth >= 1240) return '33%';
			}
			return 0;
		},
		[windowWidth, hasModal]
	);

	return { resizableContainersWidthRestriction };
};

export default useResizableContainersWidth;
