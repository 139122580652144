import React, { useContext, useEffect, useMemo, useState } from 'react';

import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';
import {
	checkMustShowAlert,
	checkMustShowTierSelector,
	getInitModalPropsByStatus,
	getInstrumentMinTier,
	getJPAcctIdForTierInfoRequest,
	getNextMonthTier,
	getStatusRelatedSubscrInfoUpdate,
	getTierNumFromName,
	SubscriptionCheckStatus,
	SubscriptionModalReason,
	SubscriptionStatus,
	SubscriptionTier,
} from '../../../utils/functions/subscriptionUtils';

import useForceRerender from '../../../utils/hooks/useForceRerender';
import useObservable from '../../../utils/hooks/useObservable';
import SubscriptionSelector from '../SubscriptionSelector/SubscriptionSelector';
import useSubscriptionInfo from '../../../utils/hooks/useSubscriptionInfo';
import useSubscriptionModal from '../../../utils/hooks/useSubscriptionModal';
import SubscriptionAlert from '../SubscriptionAlert/SubscriptionAlert';

import useHandleSubscribeResponse from '../../../utils/hooks/useHandleSubscribeResponse';
import { totalNumberOfTiers } from '../../../setup/subscriptionsConfig';
import useHandleDemoPendingExpiry from '../../../utils/hooks/useHandleDemoPendingExpiry';
import authStore from '../../../store/authStore';
import useFetchSubscriptionStatus from '../../../utils/subscriptions/useFetchSubscriptionStatus';
import tradingAccountStore from '../../../store/tradingAccountStore';

export const SubscriptionChecker = () => {
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const forceRerender = useForceRerender();
	const [subscriptionInfo, setSubscrInfoProps] = useSubscriptionInfo();
	const { modalState, setModalStateProps, resetModalStateProps } = useSubscriptionModal();
	const [checkStatus, setCheckStatus] = useState<SubscriptionCheckStatus>('NOT_STARTED');
	const isLiveMode = authStore.use.isLiveMode();
	const isJapanSubscription = tradingAccountStore.use.isJapanSubscription();

	useObservable(dashboardContext.getPropertyChangeStream('tradingAccount'), () => {
		forceRerender();
	});
	useObservable(
		appContext.getPropertyChangeStream(
			'isJapanAccount',
			'canFetchSubscrInfo',
			'accountStats',
			'subscriptionInfo',
			'subscriptionModal'
		),
		() => {
			forceRerender();
		}
	);

	const accountId = useMemo(
		() => getJPAcctIdForTierInfoRequest(appContext),
		[appContext.isJapanAccount, appContext.accountStats, isJapanSubscription]
	);

	const {
		data,
		isLoading,
		isFetching,
		error,
		refetch: fetchSubscriptionStatus,
	} = useFetchSubscriptionStatus({ enabled: appContext.canFetchSubscrInfo && !!accountId, accountNumber: +accountId! });

	const nextMonthTier = getNextMonthTier(subscriptionInfo);

	const handleCancelModal = () => {
		setModalStateProps({
			reason: null,
			isOpen: false,
			text: null,
			instrument: null,
		});
	};

	useHandleDemoPendingExpiry(checkStatus);

	useEffect(() => {
		if (isFetching && modalState.isOpen) {
			// not showing modal while re fetching the data, if the outdated data triggered the opening.
			// this prevents flashing modals
			resetModalStateProps();
			return;
		}

		if (data && !isFetching) {
			const statusRelatedUpdates = getStatusRelatedSubscrInfoUpdate(
				data.result.status as SubscriptionStatus,
				subscriptionInfo
			);

			setSubscrInfoProps({
				...statusRelatedUpdates,
				tier: data.result.currentSubscriptionTier,
				tierNum: getTierNumFromName(data.result.currentSubscriptionTier),
				nextTier: data.result.nextSubscriptionTier,
			});

			// trigger login popups for LIVE mode only
			if (isLiveMode && isJapanSubscription) {
				const initModalProps = getInitModalPropsByStatus(data.result.status);
				if (initModalProps) {
					setModalStateProps(initModalProps);
				} else if (modalState.isOpen) {
					// this is added in order to close the modal based on the updated data
					resetModalStateProps();
				}
			}

			appContext.canFetchSubscrInfo = false;
			setCheckStatus('DONE');
		}
	}, [isFetching, data, isJapanSubscription, isLiveMode]);

	useEffect(() => {
		if (error) {
			setCheckStatus('DONE');
		}
	}, [error]);

	useEffect(() => {
		if (isLoading) {
			setCheckStatus('IN_PROGRESS');
		}
	}, [isLoading]);

	useEffect(() => {
		if (
			(!isJapanSubscription && (!appContext.canFetchSubscrInfo || !accountId)) ||
			(isJapanSubscription && !accountId && !isFetching)
		) {
			return;
		}

		fetchSubscriptionStatus(); //onSuccess, onError);
	}, [accountId, appContext.canFetchSubscrInfo, appContext.accountStats, subscriptionInfo.status, isJapanSubscription]);

	// take care of possible new subscribe response status
	useHandleSubscribeResponse();
	const mustShowSelector = checkMustShowTierSelector(subscriptionInfo.status, modalState.reason);

	const mustShowAlert = useMemo(
		() => checkMustShowAlert(subscriptionInfo.status, modalState.reason),
		[subscriptionInfo.status, modalState.reason, subscriptionInfo, modalState, isJapanSubscription]
	);

	console.info(">>> SubscriptionChecker", {mustShowSelector, mustShowAlert, 'subscriptionInfo.status':subscriptionInfo.status, 'modalState.reason': modalState.reason, subscriptionInfo, modalState, isJapanSubscription})

	if (!appContext.isJapanAccount || !isJapanSubscription || !accountId || !modalState.reason || !modalState.isOpen) {
		return null;
	}

	let preselectedTier: SubscriptionTier = 'Tier2';
	let startTierNum;

	if (mustShowSelector) {
		if (modalState.reason === SubscriptionModalReason.SubscriptionForNewUser) {
			preselectedTier = 'Tier2' as SubscriptionTier;
		} else if (modalState.reason === SubscriptionModalReason.ManageSubscription) {
			if (nextMonthTier) {
				preselectedTier = nextMonthTier;
			}
		} else if (
			modalState.reason === SubscriptionModalReason.UpgradeSubscription ||
			modalState.reason === SubscriptionModalReason.Volume ||
			modalState.reason === SubscriptionModalReason.OrderSizeUpgradable ||
			modalState.reason === SubscriptionModalReason.UpgradeToAccessGym
		) {
			let newTierNum: number;
			if (subscriptionInfo.tierNum && subscriptionInfo.tierNum < totalNumberOfTiers) {
				newTierNum = subscriptionInfo.tierNum + 1;
				preselectedTier = `Tier${newTierNum}` as SubscriptionTier;
				startTierNum = newTierNum;
			} else {
				setModalStateProps({
					reason: SubscriptionModalReason.NoSuperiorTiers,
					isOpen: true,
				});
			}
		} else if (modalState.reason === SubscriptionModalReason.Instrument) {
			if (modalState.instrument) {
				const possibleMinTier = getInstrumentMinTier(modalState.instrument.code, dashboardContext.marketItems);

				if (typeof possibleMinTier === 'number') {
					preselectedTier = `Tier${possibleMinTier}` as SubscriptionTier;
					startTierNum = possibleMinTier;
				}
			}
		}
	}



	return (
		<>
			{mustShowSelector && (
				<SubscriptionSelector
					isOpen={modalState.isOpen}
					reason={modalState.reason}
					preselectedTier={preselectedTier}
					accountId={accountId}
					startFromTierNum={startTierNum}
					onCancel={handleCancelModal}
				/>
			)}
			{mustShowAlert && (
				<SubscriptionAlert
					isOpen={modalState.isOpen}
					reason={modalState.reason}
					text={modalState.text}
					accountId={accountId}
					onCancel={handleCancelModal}
				/>
			)}
		</>
	);
};

export default SubscriptionChecker;
