import React, { useContext } from 'react';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnFiltersState, OnChangeFn } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { TicketLayout } from '../../../../../../utils/functions/enums';
import InstrumentContext from '../../../../../../contexts/InstrumentContext';
import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';
import tradingViewStore from '../../../../../../store/tradingViewStore';

const RenderClearFilters = ({
	columnFilters,
	setColumnFilters,
}: {
	columnFilters: ColumnFiltersState;
	setColumnFilters: OnChangeFn<ColumnFiltersState>;
}) => {
	const { t } = useTranslation();

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const instrumentContext = useContext(InstrumentContext);

	const orderTicketAccess = useOrderTicketAccess();
	const setTicketLayout = tradingViewStore.use.setTicketLayout();

	const handleStartTrading = (event: any) => {
		event.stopPropagation();
		if (orderTicketAccess()) {
			setTicketLayout(TicketLayout.Dock);
			dashboardContext.closeAllOtherTabs();
			dashboardContext.showOrderTicket = true;
			instrumentContext.focusInstrumentSearchInput();
			dashboardContext.startTradingNow = true;
		}
		return false;
	};

	return columnFilters.length > 0 ? (
		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'search']} size="5x" />
			<div className={styles.emptyStateMessage}>{t('wtr:NO_OPEN_POSITIONS_FOUND')}</div>
			<div className={styles.emptyButtonContainer}>
				<div className={styles.clearFilters} onClick={() => setColumnFilters([])}>
					{t('wtr:CLEAR_FILTERS')}
				</div>
			</div>
		</div>
	) : (
		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'chart-pie']} size="5x" />
			<div className={styles.emptyStateMessage}>
				{appContext.isJapanAccount ? t('wtr:JP_NO_OPEN_POSITIONS') : t('en:NO_OPEN_POSITIONS')}
			</div>
			<div className={styles.emptyButtonContainer}>
				<span
					className={dashboardContext.showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
					onClick={handleStartTrading}
				>
					<FontAwesomeIcon icon={['fas', 'plus-circle']} size="1x" /> {t('en:START_TRADING')}
				</span>
			</div>
		</div>
	);
};

export default RenderClearFilters;
