import { useContext, useEffect } from 'react';
import authStore, { TradingMode } from '../../../store/authStore';
import {
	createWatchlistMap,
	DEFAULT_FEED_ID,
	defaultWatchlistPreference,
	getDefaultJapanWatchlists,
	japanSpreadWatchlistName,
} from '../../functions/WatchlistUtils';
import { AccountMarketType } from '../../../gateways/RfpGateway/rfp.types';
import accountStatusStore, { StatusStore } from '../../../store/accountStatusStore';
import tradingAccountStore from '../../../store/tradingAccountStore';
import useSaveWatchlistToPreferences from './useSaveWatchlistToPreferences';
import AppContext from '../../../contexts/AppContext';
import DashboardContext from '../../../contexts/DashboardContext';

const useWatchlistSync = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const isJapanAccount = authStore.use.isJapanAccount();
	const tradingMode = authStore.use.tradingMode();

	const setCurrentWatchList = tradingAccountStore.use.setCurrentWatchList();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const isJapanSpreadAccount = tradingAccountStore.use.isJapanSpread();
	const hasLiveAccount = accountStatusStore((state: StatusStore) => state.hasLiveAccount);
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();
	const currentWatchList = tradingAccountStore.use.currentWatchList();
	const marketItems = dashboardContext.marketItems;

	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();

	useEffect(() => {
		if (appContext.userPreferences !== null && marketItems.length > 0) {
			const { user_prefs: preferences } = appContext.userPreferences;

			const filteredByModeWatchlists = preferences.watchlists.account.filter((accountType) => {
				if (isSpreadBettingAccount) {
					return (
						accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'SpreadBetting'
					);
				} else if (isJapanSpreadAccount) {
					return (
						accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === 'JapanSpread'
					);
				} else {
					return accountType._type.toUpperCase() === tradingMode.toUpperCase() && accountType.accountType === undefined;
				}
			});

			const selectedWatchlist = filteredByModeWatchlists.find(
				(item) => item._type.toUpperCase() === tradingMode.toUpperCase()
			);
			const isJapanDemo = isJapanAccount && selectedWatchlist?._type.toUpperCase() === TradingMode.Demo.toUpperCase();

			// // TEMPORARY, overriding the saved WL for Japan DEMO
			// if (isJapanDemo && selectedWatchlist) {
			// 	// Clear all WL (Subscription like ones) but the Default for Spread account (japanSpreadWatchlistName)
			// 	selectedWatchlist.watchlist = selectedWatchlist.watchlist.filter((watchlist) => watchlist._name === japanSpreadWatchlistName);
			// }

			const hasNoWatchLists = selectedWatchlist?.watchlist.length === 0;
			const isJapanLiveWithNoLiveAccounts =
				isJapanAccount &&
				selectedWatchlist?._type.toUpperCase() === TradingMode.Live.toUpperCase() &&
				hasNoWatchLists &&
				hasLiveAccount !== undefined &&
				!hasLiveAccount;

			if (
				appContext.userPreferenceError ||
				(!isJapanAccount && hasNoWatchLists) ||
				isJapanLiveWithNoLiveAccounts ||
				(isJapanDemo && hasNoWatchLists) ||
				(isJapanAccount && isJapanSubscriptionAccount && hasNoWatchLists) ||
				(isJapanAccount && isJapanSpreadAccount && hasNoWatchLists) ||
				(isSpreadBettingAccount && hasNoWatchLists)
			) {
				filteredByModeWatchlists.forEach((account) => {
					if (isJapanAccount) {
						// this is a temporary update for https://thinkmarkets.atlassian.net/browse/WTR-5012
						// >>> Temporarily remove the option to onboard on the Subscription account
						if (isJapanSubscriptionAccount && account.accountType === undefined /*|| isJapanDemo*/) {
							account.watchlist.push(...getDefaultJapanWatchlists(DEFAULT_FEED_ID, marketItems));
							setCurrentWatchList('Tier 1');
						} else if (
							(isJapanSpreadAccount && account.accountType === 'JapanSpread') ||
							isJapanLiveWithNoLiveAccounts ||
							isJapanDemo // TEMPORARY
						) {
							account.watchlist.push(
								defaultWatchlistPreference(
									DEFAULT_FEED_ID,
									marketItems,
									japanSpreadWatchlistName,
									isSpreadBettingAccount,
									isJapanSpreadAccount || isJapanLiveWithNoLiveAccounts || isJapanDemo // this is a hack to fill the WL with JapanSpread instruments when no live account is found
								)
							);
							setCurrentWatchList(japanSpreadWatchlistName);
						}
					} else {
						// TODO: REFACTOR: For some reason this is irrelevant now, simplify in next iterations
						if (account.accountType && account.accountType === 'SpreadBetting') {
							account.watchlist.push(
								defaultWatchlistPreference(
									DEFAULT_FEED_ID,
									marketItems.filter((marketItem) => marketItem.accountMarketType === AccountMarketType.SpreadBetting),
									'My Watchlist',
									isSpreadBettingAccount,
									isJapanSpreadAccount
								)
							);
						} else {
							account.watchlist.push(
								defaultWatchlistPreference(
									DEFAULT_FEED_ID,
									marketItems,
									'My Watchlist',
									isSpreadBettingAccount,
									isJapanSpreadAccount
								)
							);
						}
						const watchlistName = account.watchlist[0]?._name ?? 'Popular Markets';
						setCurrentWatchList(watchlistName);
					}

					// Uncomment this to reset the WL stored in the DB
					// account.watchlist = [];

					dashboardContext.watchlist = account.watchlist;
					dashboardContext.mappedWatchlist = createWatchlistMap(account.watchlist);

					saveWatchlistToPreferences(account.watchlist, !!account.accountType);
					appContext.userPreferenceError = false;
				});
			}
		}
	}, [
		marketItems,
		appContext.userPreferenceError,
		appContext,
		dashboardContext.watchlist,
		dashboardContext.mappedWatchlist,
		isSpreadBettingAccount,
		currentWatchList,
		isJapanAccount,
		isJapanSubscriptionAccount,
		isJapanSpreadAccount,
		hasLiveAccount,
	]);

	return null;
};

export default useWatchlistSync;
