import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import styles from './CommunityIcon.module.scss';

interface CommunityIconProps {
	open: boolean;
	onClick: (open: boolean) => void;
}

const CommunityIcon = ({ open, onClick }: CommunityIconProps) => {
	return (
		<div
			className={cn({
				[styles.iconContainer]: true,
				[styles.iconContainerClicked]: open,
			})}
			onClick={onClick}
		>
			<FontAwesomeIcon icon={[open ? 'fas' : 'fal', 'circle-question']} className={styles.notificationIcon} />
		</div>
	);
};

export default CommunityIcon;
