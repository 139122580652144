import {
	HistoryRequest,
	HistorySize,
	HistorySubscription,
	HistoryTick,
	HistoryTickItems,
	MarketItem,
	RFPDataObjectType,
	SubscriptionAction,
	TradingAccount,
} from '../../../gateways/RfpGateway/rfp.types';
import { RFP } from '../../../gateways/RfpGateway/rfpConstants';
import RfpGateway from '../../../gateways/RfpGateway/RfpGateway';
import { getAdditionalSubscriptionPairs, getMarketItem } from '../../../utils/functions/calculations';
import { Resolver } from '../../../utils/functions/Ioc';
import { OrderedSet } from '../../../utils/functions/OrderedSet';

import { GymHistoryDataStore } from './GymHistoryDataStore';
import { GymHistoryRequest } from './GymHistoryRequest';
import { GymRefQuotesStore } from './GymRefQuotesStore';
import { GymSimulation } from '../Simulations/GymSimulation';

interface GymHistoryManagerDelegate {
	// All functions are optional
	historyDataUpdated?(dataItems: HistoryTick[], reqId: string): void;
	historyDataRejected?(reqId: string): void;
	refQuotesUpdated?(): void;
}

/**
 * The GymHistoryManager class defines the `sharedInstance` method that lets clients access
 * the unique singleton instance.
 */
class GymHistoryManager {
	private static instance: GymHistoryManager;
	private delegates = new OrderedSet<GymHistoryManagerDelegate>();
	private subDataId: string | undefined = undefined;
	private subSizeId: string | undefined = undefined;
	private data = new Map<string, GymHistoryDataStore>();

	static kTPRefQuotes = 'kRQ';

	/**
	 * The GymHistoryManager's constructor should always be private to prevent direct
	 * construction calls with the `new` operator.
	 */
	private constructor() {
		const rfpGateway = Resolver.resolve(RfpGateway);
		if (rfpGateway) {
			this.subDataId = rfpGateway.subscribeFor(RFPDataObjectType.HistoryTickItems, (message) => {
				this.historyTicksReceived(message);
			});
			this.subSizeId = rfpGateway.subscribeFor(RFPDataObjectType.HistorySize, (message) => {
				this.historySizeReceived(message);
			});
		}
	}

	addDelegate(delegate: GymHistoryManagerDelegate) {
		this.delegates.add(delegate);
	}

	removeDelegate(delegate: GymHistoryManagerDelegate) {
		this.delegates.remove(delegate);
	}

	private sendHistoryRequest(historyRequest: GymHistoryRequest) {
		const rfpGateway = Resolver.resolve(RfpGateway);
		if (rfpGateway) {
			const historySubscription: HistorySubscription = { ...historyRequest, action: SubscriptionAction.Snapshot };
			rfpGateway.send(RFP.manageHistory, historySubscription);
		}
	}

	requestHistoryData(request: GymHistoryRequest) {
		if (!this.data.has(request.reqId)) {
			const dataStore = new GymHistoryDataStore(request);
			this.data.set(request.reqId, dataStore);
		}

		this.sendHistoryRequest(request);
	}

	getHistoryRequest(reqId: string) {
		return this.data.get(reqId)?.request;
	}

	private historySizeReceived(historySize: HistorySize) {
		if (!historySize.reqId.startsWith(GymHistoryManager.kTPRefQuotes)) {
			if (historySize.size === 0) {
				console.debug(`ERROR: historySize size=0, ${historySize.code}`);
				this.delegates.forEach((element) => {
					if (element.historyDataRejected) {
						element.historyDataRejected(historySize.reqId);
					}
				});
			}
		}
	}

	private historyTicksReceived(historyTickItems: HistoryTickItems) {
		if (historyTickItems.reqId.startsWith(GymHistoryManager.kTPRefQuotes)) {
			GymRefQuotesStore.sharedInstance().addTicks(historyTickItems.historyTicks, historyTickItems.reqId);
		} else {
			const dataStore = this.data.get(historyTickItems.reqId);
			if (dataStore) {
				dataStore.addDataItems(historyTickItems.historyTicks);

				this.delegates.forEach((delegate) => {
					if (historyTickItems.reqId.startsWith(GymHistoryManager.kTPRefQuotes)) {
						GymRefQuotesStore.sharedInstance().addTicks(historyTickItems.historyTicks, historyTickItems.reqId);
						if (delegate.refQuotesUpdated) {
							delegate.refQuotesUpdated();
						}
					} else {
						if (delegate.historyDataUpdated) {
							delegate.historyDataUpdated(historyTickItems.historyTicks, historyTickItems.reqId);
						}
					}
				});
			}
		}
	}

	requestRefQuotes(account: TradingAccount, marketItem: MarketItem, simulation: GymSimulation) {
		const feedId = simulation.feedId;
		const baseCcyPos = marketItem.qCcy;

		if (!baseCcyPos) return;

		const pairs = getAdditionalSubscriptionPairs(account, marketItem);
		if (pairs) {
			pairs.forEach((pair) => {
				const info = getMarketItem(feedId, pair);
				if (info) {
					this.makeRefQuoteRequest(simulation, info);
				}
			});
		}
	}

	private makeRefQuoteRequest(simulation: GymSimulation, marketItem: MarketItem) {
		const historyRequest = {
			reqId: `${simulation.id}_${marketItem.code}`,
			feedId: simulation.feedId,
			code: simulation.code,
			priceType: simulation.priceType,
			timescale: simulation.timescale,
			startTime: simulation.startTime,
			endTime: simulation.tradeTime,
		} as HistoryRequest;
		const request = new GymHistoryRequest(historyRequest);
		request.reqId = GymHistoryManager.buildRefQuoteKey(historyRequest.reqId);
		request.code = marketItem.code;
		request.feedId = marketItem.feedId;
		if (request.timescale !== 'TS_1WEEK' && request.timescale !== 'TS_1MONTH') {
			request.timescale = 'TS_1DAY';
		}

		if (!this.data.has(request.reqId)) {
			console.debug(`Request Ref Quotes - ${request.reqId}`);
			this.requestHistoryData(request);
		}
	}

	/**
	 * The static method that controls the access to the singleton instance.
	 */
	public static sharedInstance(): GymHistoryManager {
		if (!GymHistoryManager.instance) {
			GymHistoryManager.instance = new GymHistoryManager();
		}
		return GymHistoryManager.instance;
	}

	public static buildRefQuoteKey(reqId: string) {
		return `${GymHistoryManager.kTPRefQuotes}_${reqId}`;
	}

	destructor() {
		const rfpGateway = Resolver.resolve(RfpGateway);
		if (rfpGateway) {
			if (this.subDataId) {
				rfpGateway.unsubscribeFor(this.subDataId);
			}
			if (this.subSizeId) {
				rfpGateway.unsubscribeFor(this.subSizeId);
			}
		}
	}
}

export { GymHistoryManager };
