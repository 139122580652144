import React, { useContext, useEffect, useRef, useState } from 'react';
import { NumberSize, Resizable as ReResizable, ResizableProps } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';

import AppContext from '../../../contexts/AppContext';

import { getWtrResizableConfig, WtrResizableConfigItem } from './config';

import './WtrResizable.module.scss';
import positionsStore from '../../../store/PositionsStore/positionsStore';

const LOCAL_STORAGE_KEY = 'wtrResizableWidths';

const getStoredSizes = () => {
	const storedString = localStorage.getItem(LOCAL_STORAGE_KEY);

	if (storedString) {
		try {
			return JSON.parse(storedString);
		} catch (e) {
			return null;
		}
	}

	return {};
};

const storeDimensions = (panelName: string, width: number | undefined, height: number | undefined) => {
	const initialObject = getStoredSizes();
	const newObject = {
		...initialObject,
		[panelName]: {
			width: Number(width) || null,
			height: Number(height) || null,
		},
	};

	let newStoredString = null;
	try {
		newStoredString = JSON.stringify(newObject, (_, v) => (v === undefined ? null : v));
	} catch (e) {
		return;
	}

	localStorage.setItem(LOCAL_STORAGE_KEY, newStoredString);
};

const getEnabledResizeDirection = (config: WtrResizableConfigItem) => {
	const settings = config.enable;
	const hasEnabledX = !settings || settings.right || settings.left;
	const hasEnabledY = !settings || settings.top || settings.bottom;
	return { x: hasEnabledX, y: hasEnabledY };
};

interface WtrPanelSize {
	width?: number;
	height?: number;
}

interface WtrResizableProps extends ResizableProps {
	panelName:
		| 'watchlist'
		| 'positionsGrid'
		| 'tradeTicket'
		| 'marketsGroups'
		| 'marketsNews'
		| 'simulationsList'
		| 'simulationsGrid'
		| 'signalsList'
		| 'signalsOverview';
	//Used by new design currently not implemented
	/* | 'marketsNewsRight'
    | 'marketSignals'
    | 'fallers'
    | 'risers'; */
	enableResizing?: boolean;
	setSidePanelWidth?: React.Dispatch<React.SetStateAction<number>>;
	setNewsPanelHeight?: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * This is a wrapper component to the Re-resizable component, used for storing the container sizing data.
 */
const WtrResizable = ({
	panelName,
	enableResizing,
	size,
	children,
	setSidePanelWidth,
	setNewsPanelHeight,
	...rest
}: WtrResizableProps) => {
	const isInitialMount = useRef(true);
	const appContext = useContext(AppContext);
	const config = getWtrResizableConfig(panelName, appContext.isArabic);

	const enabledDirections = config ? getEnabledResizeDirection(config) : { x: true, y: true };

	const setWatchListPanelSize = positionsStore.use.setWatchListPanelSize();
	const setGridHeight = positionsStore.use.setGridHeight();

	const defaultSize: WtrPanelSize = {};
	if (enabledDirections.x) {
		defaultSize.width = config?.minWidth || 0;
	}

	if (enabledDirections.y) {
		defaultSize.height = config?.minHeight || 0;
	}

	const [panelSize, setPanelSize] = useState<WtrPanelSize>(defaultSize);

	const handleResizeStop = (_e: MouseEvent | TouchEvent, _direction: Direction, _ref: HTMLElement, d: NumberSize) => {
		setPanelSize((prevSize) => {
			const newSize = {
				...prevSize,
			};
			if (enabledDirections.x && prevSize.width) {
				newSize.width = prevSize.width + d.width;
			}
			if (enabledDirections.y && prevSize.height) {
				newSize.height = prevSize.height + d.height;
			}
			return newSize;
		});

		if (panelName === 'positionsGrid') {
			if (panelSize.height) {
				setGridHeight(panelSize.height + d.height);
			}
		}

		if (panelName === 'watchlist') {
			setWatchListPanelSize(panelSize?.width || 0);
		}

		if (setSidePanelWidth) {
			setSidePanelWidth((state) => state + 1);
		}

		if (setNewsPanelHeight && panelSize.height) {
			setNewsPanelHeight(panelSize.height + d.height);
		}
	};

	useEffect(() => {
		if (!panelName) return;

		const size = getStoredSizes()?.[panelName];
		const newSize: WtrPanelSize = {};
		let newWidth = null;

		if (enabledDirections.x) {
			newWidth = size?.width || rest.defaultSize?.width || defaultSize.width || null;
		}

		let newHeight = null;
		if (enabledDirections.y) {
			newHeight = size?.height || rest.defaultSize?.height || defaultSize.height || null;
			if (panelName === 'positionsGrid') {
				setGridHeight(newHeight);
			}
		}

		if (newWidth) {
			newSize.width = newWidth;
		}
		if (newHeight) {
			newSize.height = newHeight;
		}

		setPanelSize(newSize);
		if (setNewsPanelHeight) {
			setNewsPanelHeight(newHeight);
		}
	}, [panelName, setNewsPanelHeight]);

	useEffect(() => {
		if (!panelName) {
			return;
		}
		if (isInitialMount.current) {
			isInitialMount.current = false;
			return;
		}
		storeDimensions(panelName, panelSize.width, panelSize.height);
	}, [panelName, panelSize.width, panelSize.height]);

	if (panelName === 'tradeTicket' && !enableResizing) return <>{children}</>;

	return (
		<ReResizable
			size={{
				width: config?.width || panelSize?.width || config?.minWidth || '100%',
				height: config?.height || panelSize?.height || config?.minHeight || '100%',
			}}
			minWidth={config?.minWidth}
			minHeight={config?.minHeight}
			maxHeight={config?.maxHeight}
			maxWidth={config?.maxWidth}
			enable={config?.enable}
			onResizeStop={handleResizeStop}
			{...rest}
		>
			{children}
		</ReResizable>
	);
};

export default WtrResizable;
